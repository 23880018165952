<template>
  <v-container fluid>
    <page-title title="request.requestDetails" :fluid="true"></page-title>
    <SzervizgarFutarForm :is-reused-form="true" />
  </v-container>
</template>

<script>
import PageTitle from 'src/components/titles/PageTitle.vue';
import SzervizgarFutarForm from 'src/components/forms/SzervizgarFutarForm.vue';

export default {
  name: 'InnerSzervizgarPage',
  components: {
    PageTitle, SzervizgarFutarForm,
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
