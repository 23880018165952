<template>
  <div v-frag>
    <div v-show="isFormLoading">
      <loading-component :loading-message="loadingComponentProps.loadingMessage"></loading-component>
    </div>
    <div v-show="!isFormLoading">
      <div v-if="!isUserCanSeeTheForm">
        <user-can-see-the-content></user-can-see-the-content>
      </div>
      <div v-else>
        <!-- GENERAL INFOS -->
        <v-container class="my-10" v-if="step === 1">
          <v-row>
            <v-col cols="12">
              <div v-html="legalText"></div>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
              <v-checkbox v-model="serviceRequestForm.generalInfos"
                          :label="$t('acceptCheckbox.generalInformation')"
                          :rules="inputRules.acceptInformation"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row justify="end" class="mx-0 mt-5">
            <v-btn class="primary"
                   :disabled="!serviceRequestForm.generalInfos"
                   @click="nextStep">
              {{ $t('button.next') }}
            </v-btn>
          </v-row>
        </v-container>
        <!-- SERVICE REQUEST FORM -->
        <v-container class="my-5" v-if="step === 2">
          <v-form v-model="serviceRequestFormIsValid">
            <!-- FORM CONTENT START -->
            <v-row>
              <!-- FORM HEADER -->
              <v-col cols="12">
                <v-card class="fill-height primary">
                  <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'" class="fill-height">
                    <v-row class="mx-0 fill-height" align-content="space-between">
                      <v-col cols="12" sm="6">
                        <v-img :src="`${publicPath}images/brand-logos/tmx-negyzet-white.png`" width="140" height="140" class="mx-auto" />
                      </v-col>
                      <v-col cols="12" sm="6" class="text-center">
                        <v-icon color="white" x-large v-show="$vuetify.breakpoint.mdAndUp" class="mb-3">
                          mdi-email
                        </v-icon>
                        <div class="text-center headline align-self-center white--text">
                          {{ $t('ourEmail') }}:<br><a class="body-1 white--text">info@tmx.hu</a>
                        </div>
                      </v-col>
                      <!--<v-col cols="12" sm="4" class="text-center">
                        <v-icon color="white" x-large v-show="$vuetify.breakpoint.mdAndUp" class="mb-5">
                          mdi-phone
                        </v-icon>
                        <div class="text-center headline align-self-center white--text">
                          {{ $t('ourPhoneNumber') }}:<br><a class="body-1 white--text">+36-1/58-58-381</a>
                        </div>
                      </v-col>-->
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- KÉSZÜLÉK ADATOK -->
              <v-col cols="12">
                <v-card class="fill-height">
                  <v-card-title>
                    <v-icon color="primary" class="mr-4">mdi-tablet-cellphone</v-icon>
                    <span class="subtitle-1">{{ $t('stepper.deviceDetails') }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                    <v-row>
                      <!--v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="serviceRequestForm.prevCrmRequestId"
                                      :label="$t('inputLabels.crmRequestId')"
                                      :rules="serviceRequestForm.prevGsmWorkSheetId ? [] : []"
                                      :hint="serviceRequestForm.prevGsmWorkSheetId ? $t('inputMessages.optionalField') : $t('inputMessages.optionalField')"
                                      persistent-hint
                                      filled
                                      :disabled="isReusedForm"
                                      required>
                        </v-text-field>
                      </v-col-->
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="serviceRequestForm.prevGsmWorkSheetId"
                                      :label="$t('inputLabels.gsmWorksheetId')"
                                      :rules="inputRules.numberRule"
                                      :hint="serviceRequestForm.prevCrmRequestId ? $t('inputMessages.optionalField') : $t('inputMessages.optionalField')"
                                      persistent-hint
                                      maxlength="9"
                                      filled
                                      :disabled="isReusedForm"
                                      required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4"></v-col>
                      <v-col cols="12" sm="6" md="4">
                        <!--v-alert border="left"
                                 colored-border
                                 :type="alertColor"
                                 elevation="1"
                                 :icon="alertIcon">
                          {{ $t('crmRequestIdOrGsmWorksheetIdIsReq') }}
                        </v-alert-->
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete v-model="serviceRequestForm.serviceRequest.brand"
                                        :items="selectFieldOptions.brands"
                                        :label="$t('inputLabels.manufacturer')"
                                        item-value="id"
                                        item-text="name"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        persistent-hint
                                        filled
                                        class="t-req"
                                        :disabled="isReusedForm"
                                        return-object
                                        required>
                          <!--template v-slot:message>
                            <b>{{ $t('inputMessages.requiredField') }}</b>
                          </template-->
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select v-model="serviceRequestForm.serviceRequest.productGroup"
                                  :items="selectFieldOptions.productGroups"
                                  item-text="name"
                                  item-value="id"
                                  :no-data-text="$t('noDataText.general')"
                                  :label="$t('inputLabels.productGroup')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  class="t-req"
                                  :disabled="isReusedForm"
                                  return-object
                                  required>
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete v-model="serviceRequestForm.serviceRequest.productTypeFull"
                                        :items="selectFieldOptions.productTypes"
                                        item-text="displayName"
                                        item-value="gsmTipusId"
                                        :search-input.sync="typeSearch"
                                        :label="$t('inputLabels.productType')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        :no-data-text="$t('noDataText.general')"
                                        persistent-hint
                                        filled
                                        class="t-req"
                                        :disabled="isReusedForm"
                                        return-object
                                        required>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn1"
                                          :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier1  || $t('inputLabels.imei1')"
                                          :rules="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1 ? inputRules.imeiRule : []"
                                          :messages="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1 ? $t('imeiHint') : $t('imeiHintOptional')"
                                          :hint="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1 ? $t('imeiHint') : $t('imeiHintOptional') "
                                          v-mask="imeiMask"
                                          counter="15"
                                          maxlength="15"
                                          persistent-hint
                                          filled
                                          :class="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1 ? 't-req' : ''"
                                          :disabled="!serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier1">
                            </v-text-field>
                          <!--<template v-if="!isReusedForm">
                              <v-slide-y-transition>
                                <v-row class="pa-0 ma-0" v-show="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1">
                                  <v-col class="px-3 py-0 caption">
                                    {{ $t('generateRandomImei') }}
                                  </v-col>
                                  <v-col cols="auto" class="pa-0">
                                    <v-btn small
                                           color="primary"
                                           @click="randomImei(1)">
                                      {{ $t('button.generate') }}
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-slide-y-transition>
                            </template>-->
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn2"
                                          :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier2 || $t('inputLabels.imei2')"
                                          :rules="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2 ? inputRules.imeiRule : []"
                                          :hint="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2 ? $t('imeiHint') : $t('inputMessages.optionalField')"
                                          :messages="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2 ? $t(`${imeiHint}`) : $t('imeiHintOptional')"
                                          v-mask="imeiMask"
                                          counter="15"
                                          maxlength="15"
                                          persistent-hint
                                          filled
                                          :class="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2 ? 't-req' : ''"
                                          :disabled="!serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier2">
                            </v-text-field>
                            <!--<template v-if="!isReusedForm">
                              <v-slide-y-transition>
                                <v-row class="pa-0 ma-0" v-show="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2">
                                  <v-col class="px-3 py-0 caption">
                                    {{ $t('generateRandomImei') }}
                                  </v-col>
                                  <v-col cols="auto" class="pa-0">
                                    <v-btn small
                                           color="primary"
                                           @click="randomImei(2)">
                                      {{ $t('button.generate') }}
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-slide-y-transition>
                            </template>-->
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn3"
                                          :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier3  || $t('inputLabels.serialNr')"
                                          :rules="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier3 ? inputRules.snRule : []"
                                          :hint="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier3 ? $t('inputMessages.requiredField') : $t('inputMessages.optionalField')"
                                          counter="22"
                                          maxlength="22"
                                          persistent-hint
                                          filled
                                          :class="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier3 ? 't-req' : ''"
                                          :disabled="!serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier3">
                            </v-text-field>
                            <!--<template v-if="!isReusedForm">
                              <v-slide-y-transition>
                                <v-row class="pa-0 ma-0" v-show="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier3">
                                  <v-col class="px-3 py-0 caption">
                                    {{ $t('generateRandomImei') }}
                                  </v-col>
                                  <v-col cols="auto" class="pa-0">
                                    <v-btn small
                                           color="primary"
                                           @click="randomImei(3)">
                                      {{ $t('button.generate') }}
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-slide-y-transition>
                            </template>-->
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select v-model="serviceRequestForm.serviceRequest.networkLock"
                                  :items="selectFieldOptions.networkLock"
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('inputLabels.simLock')"
                                  :hint="$t('inputMessages.optionalField')"
                                  persistent-hint
                                  filled
                                  :disabled="isReusedForm"
                                  return-object
                                  required>
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select v-model="serviceRequestForm.serviceRequest.warrantyType"
                                  :items="selectFieldOptions.warrantyType"
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('inputLabels.warrantyType')"
                                  :rules="inputRules.selectRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  :disabled="isReusedForm"
                                  return-object
                                  disabled
                                  required>
                        </v-select>
                      </v-col>
                      <!-- BEÍRÓS DATE PICKER START -->
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                          :label="$t('inputLabels.dateOfPurchase')"
                          :rules="inputRules.purchaseDateOptionalRule"
                          :hint="$t('inputMessages.purchaseDateOptional')"
                          persistent-hint
                          v-mask="dateTextMask"
                          filled
                          :disabled="isReusedForm">
                        </v-text-field>
                      </v-col>
                      <!-- BEÍRÓS DATE PICKER END -->
                      <v-col cols="12" sm="6">
                        <v-file-input v-model="attachment.invoice"
                                      :label="$t('inputLabels.invoice')"
                                      :rules="docInvoiceIsRequired ? inputRules.maxFileSizeRequired : inputRules.maxFileSizeOptional"
                                      :hint="docInvoiceIsRequired ? $t('inputMessages.maxFileSizeRuleRequired') : $t('inputMessages.maxFileSizeRuleOptional')"
                                      persistent-hint
                                      accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                      counter
                                      show-size
                                      filled
                                      :clearable="false"
                                      :append-icon="attachment.invoice ? 'mdi-close' : ''"
                                      @click:append="clearFile('invoice')"
                                      ref="valami">
                          <template v-slot:selection="{text}">
                            <v-chip small label color="primary">
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-file-input v-model="attachment.warrantyCard"
                                      :label="$t('inputLabels.warrantyTicket')"
                                      :rules="docWarrantyCardIsRequired ? inputRules.maxFileSizeRequired : inputRules.maxFileSizeOptional"
                                      :hint="docWarrantyCardIsRequired ? $t('inputMessages.maxFileSizeRuleRequired') : $t('inputMessages.maxFileSizeRuleOptional')"
                                      persistent-hint
                                      accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                      counter
                                      show-size
                                      :clearable="false"
                                      :append-icon="attachment.warrantyCard ? 'mdi-close' : ''"
                                      @click:append="clearFile('warrantyCard')"
                                      filled>
                          <template v-slot:selection="{text}">
                            <v-chip small label color="primary">
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col cols="12" md="4">
                        <!--v-alert border="left"
                                 colored-border
                                 :type="docAlertColor"
                                 :icon="docAlertIcon"
                                 elevation="1">
                          {{ $t('docIsReqIW') }}
                        </v-alert-->
                      </v-col>
                      <v-col cols="12" class="py-0 text-right">
                      <span class="caption">
                        {{ $t('attachDocs') }}
                      </span>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea v-model="serviceRequestForm.serviceRequest.errorDescription"
                                    :label="$t('inputLabels.troubleDescription')"
                                    :rules="inputRules.lenghtRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    persistent-hint
                                    :counter='3000'
                                    class="t-req"
                                    filled
                                    required>
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- ÜGYFÉL ADATOK -->
              <v-col cols="12" lg="6">
                <v-card class="fill-height">
                  <v-card-title>
                    <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
                    <span class="subtitle-1">{{ $t('stepper.customerDetails') }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                    <v-row>
                      <!--<v-col cols="12">
                        <v-text-field v-model="$store.state.authModule.user.profile.companyName"
                                      :label="$t('inputLabels.companyName')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      counter="90"
                                      filled
                                      :disabled=isReusedForm"
                                      required
                                      :disabled="$store.state.authModule.isAuthenticated">
                        </v-text-field>
                      </v-col>-->
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="serviceRequestForm.customer.lastName"
                                      :label="$t('inputLabels.lastName')"
                                      :rules="inputRules.noSpecialCharAndReq"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      counter="30"
                                      filled
                                      class="t-req"
                                      required
                                      :disabled="isReusedForm">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="serviceRequestForm.customer.firstName"
                                      :label="$t('inputLabels.firstName')"
                                      :rules="inputRules.noSpecialCharAndReq"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      counter="30"
                                      filled
                                      class="t-req"
                                      required
                                      :disabled="isReusedForm">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="serviceRequestForm.customer.email"
                                      :label="$t('inputLabels.email')"
                                      :rules="inputRules.emailRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      counter="50"
                                      filled
                                      class="t-req"
                                      required
                                      :disabled="isReusedForm">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="serviceRequestForm.customer.phoneNumber"
                                      :label="$t('inputLabels.phone')"
                                      :rules="inputRules.phoneRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      :prefix="getToken() ? '+' : '+36-'"
                                      v-mask=" getToken() ? userPhone : mask"
                                      filled
                                      class="t-req"
                                      required
                                      :disabled="isReusedForm">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- CÍM ADATOK START -->
              <v-col cols="12" lg="6">
                <v-card class="fill-height">
                  <v-card-title>
                    <v-icon color="primary" class="mr-4">mdi-truck-fast-outline</v-icon>
                    <span class="subtitle-1" v-if="!showFoxpost() || !deliveryMethod  && !showFoxpost() || deliveryMethod === 1 && showFoxpost() ">{{ $t('paymentAndAddressDatas') }}</span>
                    <span class="subtitle-1" v-if="deliveryMethod !== 1 && showFoxpost() ">{{ $t('costumerAddress') }}</span>
                    <v-row class='justify-end'>
                      <v-card-subtitle v-if='deliveryMethod === 2' >
                        <v-row>
                          <span class="subtitle-1">{{$t('parcelLockers') }}: {{this.foxpostAddress}}</span>
                        </v-row>
                        <v-row>
                          <span>{{$t('parcelLockersCode') }}: {{foxpostData.operator_id}}</span>
                        </v-row>
                      </v-card-subtitle>
                    </v-row>
                  </v-card-title>
                  <!-- <v-card-title>
                    <v-icon color="primary" class="mr-4">mdi-truck-fast-outline</v-icon>
                    <span class="subtitle-1">{{ $t('paymentAndAddressDatas') }}</span>
                  </v-card-title>
                  <v-row class='pl-6 justify-end'>
                    <v-card-subtitle v-if='deliveryMethod === 2' >
                      <v-row>
                        <span class="subtitle-1">{{$t('parcelLockers') }}: {{this.foxpostAddress}}</span>
                      </v-row>
                      <v-row>
                        <span>{{$t('parcelLockersCode') }}: {{foxpostData.operator_id}}</span>
                      </v-row>
                    </v-card-subtitle>
                  </v-row>-->
                  <v-divider></v-divider>
                  <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="deliveryInAddressString"
                                      :label="!showFoxpost() || !deliveryMethod && !showFoxpost() ? $t('deliveryInAddress') : deliveryMethod !== 1 && showFoxpost() ? $t('costumerAddress') : $t('deliveryInAddress') "
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      filled
                                      class="t-req"
                                      append-icon="mdi-pencil"
                                      readonly
                                      @click="deliveryInAddressModal = true"
                                      @click:append="deliveryInAddressModal = true">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="deliveryMethod !== 2">
                        <v-text-field v-model="deliveryOutAddressString"
                                      :label="$t('deliveryOutAddress')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      filled
                                      class="t-req"
                                      append-icon="mdi-pencil"
                                      readonly
                                      :disabled="deliveryOutIsEqualDeliveryIn"
                                      @click="deliveryOutAddressModal = true"
                                      @click:append="deliveryOutAddressModal = true">
                        </v-text-field>
                        <v-switch v-model="deliveryOutIsEqualDeliveryIn"
                                  :disabled="!deliveryInAddressForm"
                                  hide-details
                                  class="mt-0">
                          <template v-slot:label>
                            <div class="text-body-2">
                              {{ $t('deliveryOutAddressIsEqualDeliveryInAddress') }}
                            </div>
                          </template>
                        </v-switch>
                      </v-col>
                      <!-- <v-col cols="12" class="pb-0" v-if="serviceRequestForm.serviceRequest.availableServiceId === 33">
                        <v-checkbox v-model="billingAddressIsEqualdeliveryInAddress"
                                    class="mt-0" :disabled="!(serviceRequestForm.customer.lastName.length > 1 && deliveryInAddressForm)">
                          <template slot="label">
                          <span class="body-2">
                            {{ $t('invoicingAddressIsEqualShippingAddress') }}
                          </span>
                          </template>
                        </v-checkbox>
                      </v-col> -->
                      <template v-if="serviceRequestForm.serviceRequest.availableServiceId === 33">
                        <v-col cols="12" class="">
                          <v-text-field v-model="billingAddressString"
                                        :label="$t('invoicingAddress')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        persistent-hint
                                        filled
                                        append-icon="mdi-pencil"
                                        readonly
                                        :disabled="billingAddressIsEqualdeliveryInAddress"
                                        @click="billingAddressModal = true"
                                        @click:append="billingAddressModal = true">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select v-model="serviceRequestForm.serviceRequest.paymentMethod"
                                    :items="selectFieldOptions.paymentMethods"
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('inputLabels.paymentMethods')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    persistent-hint
                                    return-object
                                    filled
                                    required>
                          </v-select>
                        </v-col>
                      </template>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- CÍM ADATOK END -->
              <!-- REGISZTRÁCIÓ START -->
              <v-col cols="12" v-if="!$store.state.authModule.isAuthenticated && selectedService.isRegistrationVisible">
                <v-card class="fill-height">
                  <v-card-title>
                    <v-icon color="primary" class="mr-4">mdi-shield-account</v-icon>
                    <span class="subtitle-1">{{ $t('accountRegistration') }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                    <v-row>
                      <v-col cols="12" class="body-1 pb-0">
                        {{ $t('accountRegistrationQuestionOnForm') }}
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-radio-group v-model="serviceRequestForm.accountRegistration"
                                       :rules="inputRules.generalRules"
                                       :hint="$t('inputMessages.requiredField')"
                                       persistent-hint>
                          <v-radio value="yes" :label="$t('inputLabels.accountRegistrationTrue')"></v-radio>
                          <v-radio value="no" :label="$t('inputLabels.accountRegistrationFalse')"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-expand-transition>
                      <v-row v-show="serviceRequestForm.accountRegistration === 'yes'">
                        <v-col cols="12">
                          <p class="body-1">
                            Kérjük a lenti mezőben adj meg egy jelszót. Ezzel tudsz majd bejelentkezni a felhasználói fiókodba.
                            A bejelentkezési e-mail cím az Ügyfél adatoknál megadott (<b>{{ serviceRequestForm.customer.email }}</b>)
                            e-mail címed lesz.
                          </p>
                          <p>
                            A jelszónak kötelezően tartalmaznia kell minimum 1db kis-, 1 db nagybetűt és minimum 1 db számot.
                            A jelszó hossza minimum 8 karakter kell hogy legyen!
                          </p>
                        </v-col>
                        <v-col cols="12" sm="6" offset-sm="3">
                          <v-text-field v-model="serviceRequestForm.customer.password"
                                        :label="$t('inputLabels.password')"
                                        :rules="serviceRequestForm.accountRegistration === 'yes' ? inputRules.min8 : []"
                                        :hint="serviceRequestForm.accountRegistration === 'yes' ? $t('inputMessages.requiredField') : ''"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show1 ? 'text' : 'password'"
                                        persistent-hint
                                        counter="30"
                                        filled
                                        required
                                        @click:append="show1 = !show1">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-expand-transition>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- REGISZTRÁCIÓ END -->
            </v-row>
            <!-- FORM CONTENT END -->
            <!-- CHECKBOXES -->
            <v-row>
              <v-col cols="12" class="py-0">
                <div class="d-flex">
                  <v-checkbox
                    v-model="serviceRequestForm.gdpr"
                    :rules="inputRules.gdprRules"
                    required
                    class="ma-0"
                  >
                    <template v-slot:label>
                    <span v-if="$i18n.locale === 'hu-HU' || $i18n.locale === 'hu'" class="body-2">
                      Az <a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>
                      Adatvédelmi tájékoztatót</a>&nbsp;elolvastam és elfogadom.
                    </span>
                      <span v-if="$i18n.locale === 'en-EN' || $i18n.locale === 'en'" class="body-2">
                      I have read and accepted the&nbsp;<a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>
                      Privacy Policy</a>.
                    </span>
                      <span v-if="$i18n.locale === 'de-DE' || $i18n.locale === 'de'" class="body-2">
                      Ich habe die&nbsp;<a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>
                      Datenschutzbestimmungen</a> gelesen und akzeptiere sie.
                    </span>
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" class="py-0">
                <div class="d-flex">
                  <v-checkbox
                    v-model="serviceRequestForm.termsAndCond"
                    :rules="inputRules.gdprRules"
                    required
                    class="ma-0"
                  >
                    <template slot="label">
                              <span v-if="$i18n.locale === 'hu-HU' || $i18n.locale === 'hu'" class="body-2">
                                Az&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_hu.pdf`" target="_blank" @click.stop>Általános Szerződési Feltételeket</a>&nbsp;elolvastam és elfogadom.
                              </span>
                      <span v-if="$i18n.locale === 'en-EN' || $i18n.locale === 'en'" class="body-2">
                                I have read and accepted the&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_en.pdf`" target="_blank" @click.stop>Terms and Conditions</a>.
                              </span>
                      <span v-if="$i18n.locale === 'de-DE' || $i18n.locale === 'de'" class="body-2">
                                Ich habe die&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_de.pdf`" target="_blank" @click.stop>AGB</a> gelesen und akzeptiere sie.
                              </span>
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <v-col cols="12">
                <v-row justify="end">
                  <v-btn large color="primary" :disabled="!serviceRequestFormIsValid" @click="sendContactForm">
                    {{ $t('button.send') }}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <!-- SZÁLLÍTÁSI CÍM MODAL START -->
        <v-dialog v-model="deliveryInAddressModal" max-width="1200">
          <v-card tile>
            <v-card-title class="third">
              <v-icon color="white" class="mr-4">mdi-truck-check-outline</v-icon>
              <span class="white--text" v-if="!showFoxpost()">{{ $t('deliveryAndReceiptAddress') }}</span>
              <span class="white--text" v-if="showFoxpost()">{{ $t('costumerAddress') }}</span>
              <v-spacer></v-spacer>
              <v-icon @click="[deliveryInAddressModal = false , shippingAddressStepper = 1 ,deliveryMethod = 1]" color="white">
                mdi-close
              </v-icon>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-stepper v-model="shippingAddressStepper">
              <v-stepper-header  v-if='showFoxpost()'>
                <v-stepper-step
                  :complete="shippingAddressStepper > 1"
                  step="1"
                >
                  {{ $t('requireAddress') }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="shippingAddressStepper > 2"
                  step="2"
                >
                  {{ $t('deliveryMethod') }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="shippingAddressStepper > 3"
                  step="3">
                  {{ $t('parcelLockersChose') }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="shippingAddressStepper > 4"
                  step="4">
                  {{ $t('parcelLockersSubmit') }}
                </v-stepper-step>
              </v-stepper-header>
              <v-divider v-if='deliveryMethod === 2'></v-divider>
              <v-stepper-items>
                <v-stepper-content step="0">
                  <v-select v-model="customerShippingAddress"
                            :items="selectFieldOptions.customerShippingAddresses"
                            item-text="label"
                            item-value="id"
                            :label="$t('inputLabels.selectShippingAddress')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            persistent-hint
                            filled
                            return-object
                            required>
                  </v-select>
                </v-stepper-content>
                <v-stepper-content step="1" class="pa-0">
                  <v-card-text class="pa-6">
                    <v-form v-model="deliveryInAddressForm">
                      <v-row>
                        <v-col cols="12" v-if="getToken()">
                          <v-select v-model="customerShippingAddress"
                                    :items="selectFieldOptions.customerShippingAddresses"
                                    item-text="label"
                                    item-value="id"
                                    :label="$t('inputLabels.selectShippingAddress')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    persistent-hint
                                    filled
                                    return-object
                                    required>
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="customerShippingAddress.id === '5dC]Y??9f@TmbJFv' || !getToken()">
                          <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.postalCode"
                                          :items="selectFieldOptions.postalCodes"
                                          item-text="address.postalCode"
                                          item-value="address.postalCode"
                                          :search-input.sync="postalCodeSearch"
                                          :label="$t('inputLabels.postalCode')"
                                          :rules="inputRules.phoneRules"
                                          :hint="$t('inputMessages.requiredField')"
                                          :no-data-text="$t('inputMessages.noDataText')"
                                          persistent-hint
                                          filled
                                          maxlength="4"
                                          required
                                          @change="onPostalCodeSelect"
                                          @click:clear="clearAllAddressInputs"
                                          clearable>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="customerShippingAddress.id !== '5dC]Y??9f@TmbJFv' && getToken() ">
                          <v-text-field v-model="serviceRequestForm.deliveryInAddress.postalCode"
                                        :items="selectFieldOptions.postalCodes"
                                        item-text="address.postalCode"
                                        item-value="address.postalCode"
                                        :search-input.sync="postalCodeSearch"
                                        :label="$t('inputLabels.postalCode')"
                                        :rules="inputRules.phoneRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        :no-data-text="$t('inputMessages.noDataText')"
                                        persistent-hint
                                        filled
                                        required
                                        disabled
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="customerShippingAddress.id === '5dC]Y??9f@TmbJFv' || !getToken()">
                          <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.city"
                                          :items="selectFieldOptions.cities"
                                          item-value="address.city"
                                          item-text="address.city"
                                          :search-input.sync="citySearch"
                                          :label="$t('inputLabels.city')"
                                          :rules="inputRules.generalRules"
                                          :hint="$t('inputMessages.requiredField')"
                                          :no-data-text="$t('inputMessages.noDataText')"
                                          persistent-hint
                                          @change='getPostalCodeByCity'
                                          filled
                                          required
                                          clearable
                                          @click:clear="clearAllAddressInputs">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="customerShippingAddress.id !== '5dC]Y??9f@TmbJFv' && getToken()">
                          <v-text-field v-model="serviceRequestForm.deliveryInAddress.city"
                                        :items="selectFieldOptions.cities"
                                        item-value="address.city"
                                        item-text="address.city"
                                        :search-input.sync="citySearch"
                                        :label="$t('inputLabels.city')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        :no-data-text="$t('inputMessages.noDataText')"
                                        persistent-hint
                                        filled
                                        disabled>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="8" v-if="customerShippingAddress.id === '5dC]Y??9f@TmbJFv' || !getToken()">
                          <v-combobox v-model="streetIdeiglenes"
                                      :items="selectFieldOptions.streets"
                                      item-value="address.street"
                                      item-text="address.street"
                                      :search-input.sync="streetSearch"
                                      :label="$t('inputLabels.publicPlaceName')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      required
                                      @change="onPublicPlaceSelect"
                                      clearable>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="8" v-if="customerShippingAddress.id !== '5dC]Y??9f@TmbJFv' && getToken()">
                          <v-text-field v-model="serviceRequestForm.deliveryInAddress.publicPlaceName + ' ' + serviceRequestForm.deliveryInAddress.publicPlaceType"
                                        :items="selectFieldOptions.streets"
                                        item-value="address.street"
                                        item-text="address.street"
                                        :search-input.sync="streetSearch"
                                        :label="$t('inputLabels.publicPlaceName')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        :no-data-text="$t('inputMessages.noDataText')"
                                        persistent-hint
                                        filled
                                        required
                                        disabled>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" v-if="customerShippingAddress.id === '5dC]Y??9f@TmbJFv' || !getToken()">
                          <v-text-field v-model="serviceRequestForm.deliveryInAddress.streetNumber"
                                        :label="$t('inputLabels.streetNumber')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        :no-data-text="$t('inputMessages.noDataText')"
                                        counter="30"
                                        max-length="30"
                                        persistent-hint
                                        filled
                                        required
                                        clearable>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" v-if="customerShippingAddress.id !== '5dC]Y??9f@TmbJFv' && getToken()">
                          <v-text-field v-model="serviceRequestForm.deliveryInAddress.streetNumber"
                                        :label="$t('inputLabels.streetNumber')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        :no-data-text="$t('inputMessages.noDataText')"
                                        counter="30"
                                        max-length="30"
                                        persistent-hint
                                        filled
                                        required
                                        disabled
                                        clearable>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea v-model="serviceRequestForm.deliveryInAddress.other"
                                      :label="$t('infoToDeliver')"
                                      :rules="inputRules.noSpecialChar"
                                      :hint="$t('inputMessages.optionalField')"
                                      persistent-hint
                                      clearable
                                      filled
                                      clear-icon="mdi-close"
                                      @keydown.prevent.enter="preventEnter"
                                      maxlength="50"
                                      counter="50">
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="px-6 pb-6">
                    <v-spacer></v-spacer>
                    <v-btn v-if='showFoxpost()'
                           color="primary"
                           :disabled="checkAddress()"
                           @click="shippingAddressStepper = 2"
                    >
                      {{ $t('button.next') }}
                    </v-btn>
                    <v-btn text @click="deliveryInAddressModal = false">
                      {{ $t('button.cancel') }}
                    </v-btn>
                    <!--<v-btn text @click="stepBackDeliveryInAddressModal" color="primary" v-if="$store.state.authModule.isAuthenticated">
                      {{ $t('button.backToMyAddresses') }}
                    </v-btn>-->
                    <v-btn v-if='!showFoxpost()' class="primary" @click="saveDeliveryInAddressData"  :disabled="!(deliveryInAddressForm && serviceRequestForm.deliveryInAddress.publicPlaceType.length > 0)">
                      {{ $t('button.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-row>
                    <v-col cols='12' sm='6'>
                      <v-card class='ma-6 pa-5' height="200px">
                        <v-row justify="center">
                          <v-btn color='primary' class='pa-2 mt-2 mb-3' @click='[deliveryMethod = 1, saveDeliveryInAddressData()] '>{{$t('deliveryService')}}</v-btn>
                          <span class="ma-3">{{$t('deliveryServiceText')}}</span>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols='12' sm='6'>
                      <v-card class='ma-6 pa-5' height="200px">
                        <v-row justify="center">
                          <v-btn color='primary' class='pa-2 mt-2 mb-3' @click='[deliveryMethod = 2 , shippingAddressStepper = 3 ]'>{{$t('foxpost')}}</v-btn>
                          <span class="ma-3">{{$t('foxpostText')}}</span>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row justify='end'  class='mr-1 mb-1 mt-1 pa-2'>
                    <v-btn color='primary' @click="shippingAddressStepper = 1">
                      {{ $t('button.back') }}
                    </v-btn>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <iframe  loading="lazy" src="https://cdn.foxpost.hu/apt-finder/v1/app/" :height='screenCheck()' width='100%'></iframe>
                  <v-row class='justify-end mb-1 mt-2 mr-1' >
                    <v-btn color='primary' @click="[shippingAddressStepper = 2, deliveryMethod = null]">
                      {{ $t('button.back') }}
                    </v-btn>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <v-row class='pa-2 justify-center'>
                    <v-col cols="12" sm="6">
                      <v-card class='justify-center'>
                        <v-card-title class='justify-center primary white--text'>{{$t('parcelLockersSubmit') }}</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text align='center'> {{$t('parcelLockersChosen') }}: {{foxpostAddress}}</v-card-text>
                        <v-row class='justify-center'>
                          <span>{{$t('parcelLockersCode') }}: {{ foxpostData.operator_id }}</span>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class='pa-2 justify-center' v-if="foxpostPhone === 1">
                    <v-col cols="12" sm="6">
                      <v-card class='justify-center'>
                        <v-card-title class='justify-center primary white--text'>Mobil telefonszám megadása</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text align='center'>{{$t('foxpostPhoneNumberText') }} <br> {{$t('phoneServices') }}</v-card-text>
                        <v-row class='justify-center'>
                          <v-col cols='12' sm='6'>
                            <v-text-field v-model="foxpostPhoneNumber"
                                          :label="$t('inputLabels.phone')"
                                          :rules="inputRules.phoneRules"
                                          :hint="$t('inputMessages.requiredField')"
                                          persistent-hint
                                          :prefix="getToken() ? '+' : '+36'"
                                          v-mask="getToken() ? userPhone : mask"
                                          filled
                                          required
                                          :disabled="foxpostPhone !== 1">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class='pa-2 mb-1 justify-end'>
                    <v-btn class="primary" @click="saveDeliveryInAddressData" :disabled=" getToken() ? !foxpostPhoneNumberCheck() : foxpostPhoneNumberCheck()">
                      {{ $t('button.save') }}
                    </v-btn>
                    <v-btn color='primary'  text @click="shippingAddressStepper = 3">
                      {{ $t('button.back') }}
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-dialog>
        <!-- <v-dialog v-model="deliveryInAddressModal" max-width="1200" persistent>
           <v-card tile>
             <v-card-title class="third">
               <v-icon color="white" class="mr-4">mdi-truck-check-outline</v-icon>
               <span class="white--text">{{ $t('shippingAddress') }}</span>
               <v-spacer></v-spacer>
               <v-icon @click="deliveryInAddressModal = false" color="white">
                 mdi-close
               </v-icon>
             </v-card-title>
                 <v-stepper v-model="shippingAddressStepper">
               <v-stepper-items>
                 <v-stepper-content step="1">
                   <v-select v-model="customerShippingAddress"
                             :items="selectFieldOptions.customerShippingAddresses"
                             item-text="label"
                             item-value="id"
                             :label="$t('inputLabels.selectShippingAddress')"
                             :rules="inputRules.generalRules"
                             :hint="$t('inputMessages.requiredField')"
                             persistent-hint
                             filled
                             return-object
                             required>
                   </v-select>
                 </v-stepper-content>
                 <v-stepper-content step="2" class="pa-0">
                   <v-card-text class="pa-6">
                     <v-form v-model="deliveryInAddressForm">
                       <v-row>
                         <v-col cols="12" sm="6">
                           <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.postalCode"
                                           :items="selectFieldOptions.postalCodes"
                                           item-text="address.postalCode"
                                           item-value="address.postalCode"
                                           :search-input.sync="postalCodeSearch"
                                           :label="$t('inputLabels.postalCode')"
                                           :rules="inputRules.phoneRules"
                                           :hint="$t('inputMessages.requiredField')"
                                           :no-data-text="$t('inputMessages.noDataText')"
                                           persistent-hint
                                           filled
                                           maxlength="4"
                                           required
                                           @change="onPostalCodeSelect"
                                           @click:clear="clearAllAddressInputs"
                                           clearable>
                           </v-autocomplete>
                         </v-col>
                         <v-col cols="12" sm="6">
                           <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.city"
                                           :items="selectFieldOptions.cities"
                                           item-value="address.city"
                                           item-text="address.city"
                                           :search-input.sync="citySearch"
                                           :label="$t('inputLabels.city')"
                                           :rules="inputRules.generalRules"
                                           :hint="$t('inputMessages.requiredField')"
                                           :no-data-text="$t('inputMessages.noDataText')"
                                           persistent-hint
                                           filled
                                           required
                                           clearable
                                           @change='getPostalCodeByCity'
                                           @click:clear="clearAllAddressInputs">
                           </v-autocomplete>
                         </v-col>
                         <v-col cols="12" sm="8">
                           <v-combobox v-model="streetIdeiglenes"
                                       :items="selectFieldOptions.streets"
                                       item-value="address.street"
                                       item-text="address.street"
                                       :search-input.sync="streetSearch"
                                       :label="$t('inputLabels.publicPlaceName')"
                                       :rules="inputRules.generalRules"
                                       :hint="$t('inputMessages.requiredField')"
                                       :no-data-text="$t('inputMessages.noDataText')"
                                       persistent-hint
                                       filled
                                       required
                                       @change="onPublicPlaceSelect"
                                       clearable>
                           </v-combobox>
                         </v-col>
                         <v-col cols="12" sm="4">
                           <v-text-field v-model="serviceRequestForm.deliveryInAddress.streetNumber"
                                         :label="$t('inputLabels.streetNumber')"
                                         :rules="inputRules.generalRules"
                                         :hint="$t('inputMessages.requiredField')"
                                         :no-data-text="$t('inputMessages.noDataText')"
                                         persistent-hint
                                         filled
                                         required
                                         clearable>
                           </v-text-field>
                         </v-col>
                         <v-col cols="12">
                           <v-textarea v-model="serviceRequestForm.deliveryInAddress.other"
                                       :label="$t('infoToDeliver')"
                                       :rules="inputRules.noSpecialChar"
                                       :hint="$t('inputMessages.optionalField')"
                                       persistent-hint
                                       clearable
                                       filled
                                       clear-icon="mdi-close"
                                       @keydown.prevent.enter="preventEnter"
                                       maxlength="50"
                                       counter="50">
                           </v-textarea>
                         </v-col>
                       </v-row>
                     </v-form>
                   </v-card-text>
                   <v-card-actions class="px-6 pb-6">
                     <v-spacer></v-spacer>
                     <v-btn text @click="deliveryInAddressModal = false">
                       {{ $t('button.cancel') }}
                     </v-btn>
                     <v-btn text @click="stepBackDeliveryInAddressModal"
                            color="primary"
                            v-if="$store.state.authModule.isAuthenticated || isReusedForm">
                       {{ $t('button.backToMyAddresses') }}
                     </v-btn>
                     <v-btn class="primary" @click="saveDeliveryInAddressData" :disabled="!deliveryInAddressForm">
                       {{ $t('button.save') }}
                     </v-btn>
                   </v-card-actions>
                 </v-stepper-content>
               </v-stepper-items>
             </v-stepper>
           </v-card>
         </v-dialog> -->
        <!-- SZÁLLÍTÁSI CÍM MODAL END -->
        <!-- KISZÁLLÍTÁSI CÍM MODAL START -->
        <v-dialog v-model="deliveryOutAddressModal" max-width="800" persistent>
          <v-card tile>
            <v-card-title class="third">
              <v-icon color="white" class="mr-4">mdi-truck-check-outline</v-icon>
              <span class="white--text">{{ $t('deliveryOutAddress') }}</span>
              <v-spacer></v-spacer>
              <v-icon @click="deliveryOutAddressModal = false" color="white">
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-stepper v-model="shippingAddressOutStepper">
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-select v-model="customerShippingOutAddress"
                            :items="selectFieldOptions.customerShippingOutAddresses"
                            item-text="label"
                            item-value="id"
                            :label="$t('inputLabels.selectShippingAddress')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            persistent-hint
                            filled
                            return-object
                            required>
                  </v-select>
                </v-stepper-content>
                <v-stepper-content step="2" class="pa-0">
                  <v-card-text class="pa-6">
                    <v-form v-model="deliveryOutAddressForm">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-autocomplete v-model="serviceRequestForm.deliveryOutAddress.postalCode"
                                          :items="selectFieldOptions.postalCodesOut"
                                          item-text="address.postalCode"
                                          item-value="address.postalCode"
                                          :search-input.sync="deliveryOutAddressPostalCodeSearch"
                                          :label="$t('inputLabels.postalCode')"
                                          :rules="inputRules.phoneRules"
                                          :hint="$t('inputMessages.requiredField')"
                                          :no-data-text="$t('inputMessages.noDataText')"
                                          persistent-hint
                                          filled
                                          maxlength="4"
                                          required
                                          @change="onPostalCodeSelectDeliveryOut"
                                          @click:clear="clearAllAddressInputsDeliveryOut"
                                          clearable>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-autocomplete v-model="serviceRequestForm.deliveryOutAddress.city"
                                          :items="selectFieldOptions.citiesOut"
                                          item-value="address.city"
                                          item-text="address.city"
                                          :search-input.sync="deliveryOutAddressCitySearch"
                                          :label="$t('inputLabels.city')"
                                          :rules="inputRules.generalRules"
                                          :hint="$t('inputMessages.requiredField')"
                                          :no-data-text="$t('inputMessages.noDataText')"
                                          persistent-hint
                                          filled
                                          required
                                          clearable
                                          @change='getPostalCodeByCityDeliveryOut'
                                          @click:clear="clearAllAddressInputsDeliveryOut">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-combobox v-model="deliveryOutAddressStreetIdeiglenes"
                                      :items="selectFieldOptions.streetsOut"
                                      item-value="address.street"
                                      item-text="address.street"
                                      :search-input.sync="deliveryOutAddressStreetSearch"
                                      :label="$t('inputLabels.publicPlaceName')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      required
                                      @change="onPublicPlaceSelectDeliveryOut"
                                      clearable>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field v-model="serviceRequestForm.deliveryOutAddress.streetNumber"
                                        :label="$t('inputLabels.streetNumber')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        :no-data-text="$t('inputMessages.noDataText')"
                                        persistent-hint
                                        filled
                                        required
                                        clearable>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea v-model="serviceRequestForm.deliveryOutAddress.other"
                                      :label="$t('infoToDeliver')"
                                      :rules="inputRules.noSpecialChar"
                                      :hint="$t('inputMessages.optionalField')"
                                      persistent-hint
                                      clearable
                                      filled
                                      clear-icon="mdi-close"
                                      @keydown.prevent.enter="preventEnterDeliveryOut"
                                      maxlength="50"
                                      counter="50">
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="px-6 pb-6">
                    <v-spacer></v-spacer>
                    <v-btn text @click="deliveryOutAddressModal = false">
                      {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn text @click="stepBackDeliveryOutAddressModal"
                           color="primary"
                           v-if="$store.state.authModule.isAuthenticated || isReusedForm">
                      {{ $t('button.backToMyAddresses') }}
                    </v-btn>
                    <v-btn class="primary" @click="saveDeliveryInAddressDataDeliveryOut"
                           :disabled="!deliveryOutAddressForm">
                      {{ $t('button.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-dialog>
        <!-- KISZÁLLÍTÁSI CÍM MODAL END -->
        <!-- SZÁMLÁZÁSI CÍM MODAL -->
        <v-dialog v-model="billingAddressModal" max-width="800" persistent>
          <v-card tile>
            <v-card-title class="third">
              <v-icon color="white" class="mr-4">mdi-file-account-outline</v-icon>
              <span class="white--text">{{ $t('invoicingAddress') }}</span>
              <v-spacer></v-spacer>
              <v-icon @click="billingAddressModal = false" color="white">
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-stepper v-model="billingAddressStepper">
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-select v-model="customerBillingAddress"
                            :items="selectFieldOptions.customerBillingAddresses"
                            item-text="label"
                            item-value="id"
                            :label="$t('inputLabels.selectBillingAddress')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            persistent-hint
                            filled
                            return-object
                            required>
                  </v-select>
                </v-stepper-content>
                <v-stepper-content step="2" class="px-0">
                  <v-card-text class="pa-6">
                    <v-form v-model="billingAddressForm">
                      <v-row>
                        <v-col cols="12" class="pt-0">
                          <v-radio-group v-model="serviceRequestForm.billingAddress.isCompany"
                                         row mandatory class="mt-0" hide-details>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <div :class="serviceRequestForm.billingAddress.isCompany === 0 ? 'bra' : 'bri'"
                                     class="pa-3">
                                  <v-radio :value="0">
                                    <template slot="label">
                                      <span class="text-uppercase br-label">{{ $t('inputLabels.privatePerson') }}</span>
                                    </template>
                                  </v-radio>
                                </div>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <div :class="serviceRequestForm.billingAddress.isCompany === 1 ? 'bra' : 'bri'"
                                     class="pa-3">
                                  <v-radio :value="1">
                                    <template slot="label">
                                      <span class="text-uppercase br-label">{{ $t('inputLabels.company') }}</span>
                                    </template>
                                  </v-radio>
                                </div>
                              </v-col>
                            </v-row>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="serviceRequestForm.billingAddress.billingName"
                                        :label="$t('invoicingName')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        persistent-hint
                                        filled>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="serviceRequestForm.billingAddress.taxNumber"
                                        :label="$t('vatNumber')"
                                        :rules="serviceRequestForm.billingAddress.isCompany === 1 ? inputRules.vatRules : []"
                                        :hint="serviceRequestForm.billingAddress.isCompany === 1 ? $t('inputMessages.requiredField') : $t('inputMessages.optionalField')"
                                        v-mask="taxNumberMaskHU"
                                        persistent-hint
                                        filled>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-autocomplete v-model="serviceRequestForm.billingAddress.postalCode"
                                          :items="selectFieldOptions.postalCodesInvoice"
                                          item-text="address.postalCode"
                                          item-value="address.postalCode"
                                          :search-input.sync="postalCodeSearchInvoice"
                                          :label="$t('inputLabels.postalCode')"
                                          :rules="inputRules.phoneRules"
                                          :hint="$t('inputMessages.requiredField')"
                                          :no-data-text="$t('inputMessages.noDataText')"
                                          persistent-hint
                                          filled
                                          maxlength="4"
                                          required
                                          @change="onPostalCodeSelectInvoice"
                                          @click:clear="clearAllAddressInputsInvoice"
                                          clearable>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-autocomplete v-model="serviceRequestForm.billingAddress.city"
                                          :items="selectFieldOptions.citiesInvoice"
                                          item-value="address.city"
                                          item-text="address.city"
                                          :search-input.sync="citySearchInvoice"
                                          :label="$t('inputLabels.city')"
                                          :rules="inputRules.generalRules"
                                          :hint="$t('inputMessages.requiredField')"
                                          :no-data-text="$t('inputMessages.noDataText')"
                                          persistent-hint
                                          filled
                                          required
                                          clearable
                                          @change='getPostalCodeByCityInvoice'
                                          @click:clear="clearAllAddressInputsInvoice">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-combobox v-model="streetIdeiglenesInvoice"
                                      :items="selectFieldOptions.streetsInvoice"
                                      item-value="address.street"
                                      item-text="address.street"
                                      :search-input.sync="streetSearchInvoice"
                                      :label="$t('inputLabels.publicPlaceName')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      required
                                      @change="onPublicPlaceSelectInvoice"
                                      clearable>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field v-model="serviceRequestForm.billingAddress.streetNumber"
                                        :label="$t('inputLabels.streetNumber')"
                                        :rules="inputRules.generalRules"
                                        :hint="$t('inputMessages.requiredField')"
                                        :no-data-text="$t('inputMessages.noDataText')"
                                        counter="30"
                                        max-length="30"
                                        persistent-hint
                                        filled
                                        required
                                        clearable>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea v-model="serviceRequestForm.billingAddress.other"
                                      :label="$t('infoToDeliver')"
                                      :rules="inputRules.noSpecialChar"
                                      :hint="$t('inputMessages.optionalField')"
                                      persistent-hint
                                      clearable
                                      filled
                                      clear-icon="mdi-close"
                                      maxlength="50"
                                      counter="50">
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="px-6 pb-6">
                    <v-spacer></v-spacer>
                    <v-btn text @click="billingAddressModal = false">
                      {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn text @click="stepBackBillingAddressModal"
                           color="primary"
                           v-if="$store.state.authModule.isAuthenticated || isReusedForm">
                      {{ $t('button.backToMyAddresses') }}
                    </v-btn>
                    <v-btn class="primary" @click="saveBillingAddressData" :disabled="!billingAddressForm">
                      {{ $t('button.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-dialog>
        <!-- SZÁMLÁZÁI CÍM MODAL END -->
        <!-- RESPONSE DIALOGS START -->
        <v-dialog v-model="loadingDialog.visible" width="90%" max-width="340" :persistent="true">
          <loading-dialog v-bind:loading-message="loadingDialog.message" />
        </v-dialog>
        <v-dialog v-model="responseDialog.visible" width="90%" max-width="340" :persistent="true">
          <response-dialog v-bind:response-object="responseDialog.response"
                           v-on:close-dialog="closeResponseDialog"/>
        </v-dialog>
        <v-dialog v-model="noFileDialog.visible" width="90%" max-width="340" :persistent="true">
          <response-dialog v-bind:response-object="noFileDialog.response"
                           v-on:close-dialog="closeNoFileDialog"/>
        </v-dialog>
        <!-- RESPONSE DIALOGS END -->
        <!-- NOT VALID PDF DIALOG START -->
        <v-dialog v-model="attachmentChecker.visible" width="90%" max-width="340" :persistent="true">
          <response-dialog v-bind:response-object="attachmentChecker.response"
                           v-on:close-dialog="closeNotValidPdfDialog"/>
        </v-dialog>
        <!-- NOT VALID PDF DIALOG END -->
      </div>
    </div>
  </div>
</template>

<script>
import ResponseDialog from 'src/components/dialogs/ResponseDialog.vue';
import LoadingDialog from 'src/components/dialogs/LoadingDialog.vue';
import dayjs from 'dayjs';
import {
  HEREGETPOSTALCODESBYCOUNTRY,
  HEREGETCITYBYPOSTALCODE,
  HEREGETSTREETBYPOSTALCODE,
  HEREGETPOSTALCODESBYCITY,
} from 'src/utils/hereAPI';
import { getToken, getUid, saveUid } from 'src/utils/jwtHelper';
import LoadingComponent from 'src/components/shared/LoadingComponent.vue';
import UserCanSeeTheContent from 'src/components/shared/UserCanSeeTheContent.vue';

export default {
  name: 'SzervizgarFutarForm',
  components: {
    ResponseDialog, LoadingDialog, LoadingComponent, UserCanSeeTheContent,
  },
  props: {
    serviceId: {
      type: Number,
      default: 300,
    },
    isReusedForm: {
      type: Boolean,
      default: false,
    },
  },
  async beforeMount() {
    //
    const response = await this.$store.dispatch('showFoxPost', 'FOXPOST_FRONTEND_AVAILABLE');
    if (response.status === this.$HTTP_OK) {
      if (response.data.value === 1) {
        this.foxPostdb = 1
      }
      if (response.data.value === 'ALL') {
        this.foxPostdb = 1
      }
      if (response.data.value === 'NONE') {
        this.foxPostdb = 0
      }
      if (response.data.value === 'OOW') {
        this.foxPostdb = 0
      }
      if (response.data.value === 'IW') {
        this.foxPostdb = 1
      }
    }
  },
  async mounted() {
    // console.log(`%cisReusedForm: ${this.isReusedForm}`, 'color: white; background: #3498db; font-size: 13px;');
    await this.callShowForm();
    this.isUserCanSeeTheForm = await this.CheckIsUserCanSeeTheForm();
    this.isFormLoading = false;
    if (this.isUserCanSeeTheForm) {
      await this.callShowPaymentMethodOfCustomerType(this.serviceRequestForm.customer.customerType);
      await this.setDates();
      await this.setServiceRequestFormCountry();
      if (await getToken()) {
        if (this.$route.meta.layout === 'outer') {
          if (this.isReusedForm) {
            if (this.$route.params.identicalHash) {
              const response = await this.$store.dispatch('loginProcess', this.$route.params.identicalHash);
              if (response.status === 200) {
                const serviceRequestResponse = await this.getRequestById(response.data.crmRequestId);
                this.oldAVS = serviceRequestResponse.serviceRequest.availableServiceId;
                console.log('#BAL - this.oldAVS: ', this.oldAVS);
                saveUid(serviceRequestResponse.customer.id);
                await this.$store.dispatch('GetCustomer');
                await this.$store.dispatch('GetAddresses');
                await this.autoFillTheForm(serviceRequestResponse);
              }
            }
          }
        }
        if (await getUid()) {
          // await this.callShowPaymentMethodOfCustomerType(this.$store.state.authModule.user.profile.customerType);
          if (this.isReusedForm) {
            if (this.$route.params.crmRequestId) {
              const serviceRequestResponse = await this.getRequestById(this.$route.params.crmRequestId);
              this.oldAVS = serviceRequestResponse.serviceRequest.availableServiceId;
              await this.autoFillTheForm(serviceRequestResponse);
            }
          }
          await this.setCustomerDatas();
          await this.setCustomerAddresses();
          this.selectFieldOptions.customerShippingAddresses = this.deleteIdenticalAddresses(this.selectFieldOptions.customerShippingAddresses);
          this.selectFieldOptions.customerShippingOutAddresses = this.deleteIdenticalAddresses(this.selectFieldOptions.customerShippingOutAddresses);
          this.selectFieldOptions.customerBillingAddresses = this.deleteIdenticalAddresses(this.selectFieldOptions.customerBillingAddresses);
          this.selectFieldOptions.customerShippingAddresses.push({
            id: '5dC]Y??9f@TmbJFv',
            label: 'Új címet adok meg'
          });
          this.selectFieldOptions.customerShippingOutAddresses.push({
            id: '5dC]Y??9f@TmbJFv',
            label: 'Új címet adok meg'
          });
          this.selectFieldOptions.customerBillingAddresses.push({
            id: '5dC]Y??9f@TmbJFv',
            label: 'Új címet adok meg'
          });
          this.shippingAddressStepper = 1;
          this.shippingAddressOutStepper = 1;
          this.billingAddressStepper = 1;
          this.setDefaultAddresses();
        }
      }
    }
    window.addEventListener('message', await this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message',this.receiveMessage)
  },
  data() {
    return {
      foxPostdb: '',
      foxpostPhoneNumber: '',
      foxpostPhone: 0,
      oldAVS: null,
      loadingComponentProps: {
        loadingMessage: 'loadingMessages.INPROGRESS_FORMLOADING',
      },
      isFormLoading: true,
      isUserCanSeeTheForm: false,
      // igény leadási form
      serviceRequestFormIsValid: false,
      serviceRequestForm: {
        formType: 4,
        processType: 1,
        prevCrmRequestId: null,
        prevGsmWorkSheetId: null,
        sourceSystem: null,
        externalId: null,
        serviceRequest: {
          partnerWorksheetNumber: null,
          prevCrmRequestId: null,
          prevGsmWorkSheetId: null,
          availableServiceId: 31,
          brand: {},
          productGroup: {},
          productTypeFull: null,
          productType: {},
          uniqueIdentifierIn1: '',
          uniqueIdentifierIn2: '',
          uniqueIdentifierIn3: '',
          warrantyType: {},
          dateOfPurchase: null,
          errorDescription: '',
          networkLock: {},
          mobilProvider: {},
          insuranceNumber: '',
          rmaNumber: '',
          accessories: null,
          paymentMethod: null,
          deductible: null,
          isLoan: null,
          otherComment: '',
        },
        customer: {
          id: null,
          customerType: 'B2C',
          crmNamePrefixId: null,
          firstName: '',
          middleName: null,
          lastName: '',
          email: '',
          phoneNumber: '',
          password: '',
        },
        deliveryInAddress: {
          id: null,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
          isCompany: 0,
        },
        deliveryOutAddress: {
          id: null,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
          isCompany: 0,
        },
        billingAddress: {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
        gdpr: false,
        termsAndCond: false,
        generalInfos: false,
        accountRegistration: null,
        isAutomaticQuotationAccepting: null,
        isVipService: null,
        isAcceptanceOfUsedParts: null,
        calculatedRepairPrice: null,
        calcDataId: null,
      },
      attachmentChecker: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'ERROR_MESSAGES.FILE_NOT_VALID',
        },
      },
      // típuskereséshez
      typeSearch: '',
      // szállítási cím
      deliveryInAddressString: '',
      deliveryInAddressForm: false,
      deliveryInAddressModal: false,
      countrySearch: '',
      countrySearchIsLoading: false,
      postalCodeSearch: '',
      postalCodeSearchIsLoading: false,
      citySearch: '',
      citySearchIsLoading: false,
      streetSearch: '',
      streetSearchIsLoading: false,
      streetIdeiglenes: '',
      deliveryInAddressBackup: null,
      // kiszállítási cím
      deliveryOutAddressString: '',
      deliveryOutAddressForm: false,
      deliveryOutAddressModal: false,
      deliveryOutAddressCountrySearch: '',
      deliveryOutAddressCountrySearchIsLoading: false,
      deliveryOutAddressPostalCodeSearch: '',
      deliveryOutAddressPostalCodeSearchIsLoading: false,
      deliveryOutAddressCitySearch: '',
      deliveryOutAddressCitySearchIsLoading: false,
      deliveryOutAddressStreetSearch: '',
      deliveryOutAddressStreetSearchIsLoading: false,
      deliveryOutAddressStreetIdeiglenes: '',
      deliveryOutAddressBackup: null,
      // számlázási cím
      billingAddressString: '',
      billingAddressForm: false,
      billingAddressModal: false,
      postalCodeSearchInvoice: '',
      postalCodeSearchIsLoadingInvoice: false,
      citySearchInvoice: '',
      citySearchIsLoadingInvoice: false,
      streetSearchInvoice: '',
      streetSearchIsLoadingInvoice: false,
      streetIdeiglenesInvoice: '',
      billingAddressBackup: null,
      // számlázási cím megegyezik a szállítási címmel
      billingAddressIsEqualdeliveryInAddress: false,
      // a kiszállítáai cím megegyezik a beszállítási címmel
      deliveryOutIsEqualDeliveryIn: false,
      // user által megadott címek kezeléséhez
      customerShippingAddress: {},
      customerShippingOutAddress: {},
      customerBillingAddress: {},
      shippingAddressStepper: 1,
      shippingAddressOutStepper: 2,
      billingAddressStepper: 2,
      brand: {},
      selectedService: {
        isGeneralVisible: false,
        isRegistrationVisible: false,
      },
      selectedProductGroup: {},
      legalText: '',
      step: 2,
      deliveryMethod: null,
      foxpostAddress: '',
      foxpostData: [],
      show1: false,
      mask: '#########',
      userPhone: '###########',
      taxNumberMaskHU: '########-#-##',
      imeiMask: '###############',
      modal: false,
      yearPickerModal: false,
      dateTextMask: '####-##-##',
      contactFormIsValid: false,
      contactFormCheckbox: false,
      imeiHint: '',
      maxDate: null,
      availableCustomerTypes: [],
      loadingDialog: {
        visible: false,
        message: 'loadingMessages.INPROGRESS_CONTACT_FORM',
      },
      responseDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'successMessages.SUCCESS_REPAIR_FORM',
        },
      },
      noFileDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'ERROR_MESSAGES.ONE_FILE_IS_REQUIRED',
        },
      },
      attachment: {
        invoice: null,
        warrantyCard: null,
      },
      selectFieldOptions: {
        brands: [],
        productGroups: [],
        productTypes: [],
        warrantyType: [],
        networkLock: [],
        paymentMethods: [],
        origRequestSource: this.$store.state.selectfields.origRequestSource,
        garancia: [
          {
            id: 1,
            name: 'Garanciális javítás',
          },
          {
            id: 2,
            name: 'Fizetős javítás',
          },
        ],
        garanciaEN: [
          {
            id: 1,
            name: 'In warranty service',
          },
          {
            id: 2,
            name: 'Out of warranty service',
          },
        ],
        garanciaDE: [
          {
            id: 1,
            name: 'Garantiereparatur',
          },
          {
            id: 2,
            name: 'Nein Garantiereparatur',
          },
        ],
        /* networkLock: this.$store.state.selectfields.simLock,
        networkLockEN: this.$store.state.selectfields.simLockEN,
        networkLockDE: this.$store.state.selectfields.simLockDE, */

        countries: [
          { label: 'Magyarország', countryCode: 'HUN' },
        ],
        countriesEN: [
          { label: 'Hungary', countryCode: 'HUN' },
        ],
        countriesDE: [
          { label: 'Ungarn', countryCode: 'HUN' },
        ],
        postalCodes: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        cities: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streets: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        // kiszállítási címhez
        postalCodesOut: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        citiesOut: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streetsOut: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        customerShippingAddresses: [],
        customerShippingOutAddresses: [],
        customerBillingAddresses: [],
        // számlázási címhez selectek
        postalCodesInvoice: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        citiesInvoice: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streetsInvoice: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        /* paymentMethods: this.$store.state.selectfields.paymentMethods,
        paymentMethodsEN: this.$store.state.selectfields.paymentMethodsEN,
        paymentMethodsDE: this.$store.state.selectfields.paymentMethodsDE, */
      },
      inputRules: {
        acceptInformation: [(v) => !!v || this.$t('inputMessages.acceptInformation')],
        generalRules: [(v) => !!v || this.$t('inputMessages.requiredField')],
        lenghtRules: [v => v.length <= 3000 || this.$t('inputMessages.maxCharacter'),
          v => !!v || this.$t('inputMessages.requiredField')],
        emailRules: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => !(/\s/.test(v)) || this.$t('inputMessages.emailNoSpace'),
          v => /.+@.+\..+/.test(v) || this.$t('inputMessages.emailMatch'),
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('inputMessages.wrongCharacter'),
        ],
        vatRules: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => (v && v.length === 13) || this.$t('inputMessages.requiredField'),
        ],
        phoneRules: [
          (v) => /^[0-9-]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          (v) => !!v || this.$t('inputMessages.requiredField'),
        ],
        gdprRules: [
          (v) => !!v || this.$t('inputMessages.requiredPrivacy'),
        ],
        termAndCondRules: [
          (v) => !!v || this.$t('inputMessages.requiredTermsAndCond'),
        ],
        maxFileSize: [
          (v) => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
        ],
        maxFileSizeRequired: [
          (value) => !!value || this.$t('inputMessages.maxFileSizeRuleRequired'),
          (value) => !value || value.size < 5000000 || this.$t('inputMessages.maxFileSizeRuleRequired'),
        ],
        maxFileSizeOptional: [
          (v) => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRuleOptional'),
        ],
        requiredFile: [
          (v) => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
          (v) => !!v || this.$t('inputMessages.requiredField'),
        ],
        min8: [
          (v) => v.length >= 8 || this.$t('inputMessages.passwordRuleMin8'),
          v => !!v || this.$t('inputMessages.requiredField')
        ],
        imeiRule: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => /^[0-9]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          (v) => (v && v.length === 15) || this.$t('inputMessages.minLenght15'),
        ],
        snRule: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => /^[a-zA-Z0-9-\/\\]*$/.test(v) || this.$t('inputMessages.snRules'),
          (v) => (v && v.length < 23) || this.$t('inputMessages.minLenght15'),
        ],
        selectRules: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => v.id > 0 || this.$t('inputMessages.requiredField'),
        ],
        purchaseDateRule: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => this.purchaseDateIsOK(v) || `${this.$t('inputMessages.purchaseDateMax')} ${this.maxDate}!`,
        ],
        purchaseDateOptionalRule: [
          (v) => this.purchaseDateIsOK(v, true) || `${this.$t('inputMessages.purchaseDateMax')} ${this.maxDate}!`,
        ],
        noSpecialCharAndReq: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.requiredFieldNoSpecialChar'),
        ],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
        numberRule: [
          (v) => this.numberRules(v, true) ||  /^[0-9-]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
         // (v) => !!v || this.$t('inputMessages.optionalField'),
        ],
      },
      publicPath: process.env.BASE_URL,
      CC_avs: [101, 102, 127],
    };
  },
  methods: {
    checkAddress() {
      if (this.showFoxpost()) {
        if (this.customerShippingAddress.id !== '5dC]Y??9f@TmbJFv' && getToken()) {
          return false
        } else {
          return !(this.deliveryInAddressForm && this.serviceRequestForm.deliveryInAddress.publicPlaceType.length > 0)
        }
      } else {
        return !(this.deliveryInAddressForm && this.serviceRequestForm.deliveryInAddress.publicPlaceType.length > 0)
      }
    },
    getToken,
    foxpostPhoneNumberCheck() {
      if (getToken()) {
        if (this.foxpostPhone === 1) {
          if (this.foxpostPhoneNumber.length >= 11) {
            const check = this.foxpostPhoneNumber.toString().slice(2, 4);
            if (['20', '30', '31', '70', '50', '51'].includes(check)) {
              return true
            } else {
              return false
            }
          }
          else {
            return false
          }
        } else {
          const check = this.foxpostPhoneNumber.toString().slice(2, 4);
          if (['20', '30', '31', '70', '50', '51'].includes(check)) {
            return true
          } else {
            return false
          }
        }
      } else {
        if (this.foxpostPhoneNumber.length >= 9) {
          const check = this.foxpostPhoneNumber.toString().slice(0, 2);
          if (['20', '30', '31', '70', '50', '51'].includes(check)) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }
    },
    async phoneNumberCheck() {
      if(getToken()) {
        console.log(String(this.serviceRequestForm.customer.phoneNumber).slice(2,4))
        const check = String(this.serviceRequestForm.customer.phoneNumber).slice(2,4);
        console.log(check);
        if(['20','30','31','70','50','51'].includes(check)) {
          this.foxpostPhone = 0
          this.foxpostPhoneNumber = this.serviceRequestForm.customer.phoneNumber
        }
        else {
          this.foxpostPhone = 1
        }
      } else {
        const check = String(this.serviceRequestForm.customer.phoneNumber.slice(0,2));
        if(['20','30','31','70','50','51'].includes(check)) {
          this.foxpostPhone = 0
          this.foxpostPhoneNumber = this.serviceRequestForm.customer.phoneNumber
        }
        else {
          this.foxpostPhone = 1
        }
      }
    },
    screenCheck() {
      const height = window.screen.availHeight
      if (height >=  1180) {
        return '900'
      }
      else {
        return '600'
      }
    },
    setFoxpostData() {
      let deliveryData = {
        category: 'automata',
        delivererId: 6,
        fixPointDeliveryIn: this.foxpostData.operator_id,
        fixPointDeliveryOut: this.foxpostData.operator_id,
        parcelUserPhone: this.foxpostPhoneNumber
      }
      this.serviceRequestForm['deliveryData'] = deliveryData
    },
    showFoxpost() {
      if (this.foxPostdb === 1) {
        if (this.serviceRequestForm.serviceRequest.brand.id === 2) {
          return false
        } else {
          if (this.serviceRequestForm.serviceRequest.productGroup.id === 1) {
            return true
          }
          if (this.serviceRequestForm.serviceRequest.productGroup.id === 2) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    },
    async receiveMessage (event) {
      let apt = null;
      apt = JSON.parse(event.data);
      if (apt != null) {
        this.foxpostAddress = apt.address
        this.foxpostData = apt
        this.deliveryMethod = 2;
        await this.phoneNumberCheck()
        this.shippingAddressStepper = 4
        console.log(this.foxpostData);
      }
    },
    async autoFillTheForm(serviceRequest) {
      this.serviceRequestForm = {
        formType: 4,
        processType: 1,
        prevCrmRequestId: serviceRequest.serviceRequest.id,
        prevGsmWorkSheetId: serviceRequest.serviceRequest.gsmWorksheetId,
        sourceSystem: null,
        externalId: null,
        serviceRequest: {
          partnerWorksheetNumber: serviceRequest.serviceRequest.partnerWorksheetNumber,
          prevCrmRequestId: serviceRequest.serviceRequest.id,
          prevGsmWorkSheetId: serviceRequest.serviceRequest.gsmWorksheetId,
          availableServiceId: 31,
          brand: serviceRequest.serviceRequest.brand,
          productGroup: serviceRequest.serviceRequest.productGroup,
          productTypeFull: null,
          productType: serviceRequest.serviceRequest.productType,
          uniqueIdentifierIn1: serviceRequest.serviceRequest.uniqueIdentifierIn1,
          uniqueIdentifierIn2: serviceRequest.serviceRequest.uniqueIdentifierIn2,
          uniqueIdentifierIn3: serviceRequest.serviceRequest.uniqueIdentifierIn3,
          warrantyType: this.selectFieldOptions.warrantyType[0],
          dateOfPurchase: serviceRequest.serviceRequest.purchaseDate,
          errorDescription: '',
          networkLock: serviceRequest.serviceRequest.networkLock,
          mobilProvider: serviceRequest.serviceRequest.mobilProvider,
          insuranceNumber: serviceRequest.serviceRequest.insuranceNumber,
          rmaNumber: serviceRequest.serviceRequest.rmaNumber,
          accessories: serviceRequest.serviceRequest.accessories,
          paymentMethod: serviceRequest.serviceRequest.paymentMethod,
          deductible: serviceRequest.serviceRequest.deductible,
          isLoan: null,
          otherComment: serviceRequest.serviceRequest.otherComment,
        },
        customer: {
          id: serviceRequest.customer.id,
          customerType: serviceRequest.customer.customerType,
          crmNamePrefixId: null,
          firstName: serviceRequest.customer.firstName,
          middleName: null,
          lastName: serviceRequest.customer.lastName,
          email: serviceRequest.customer.email,
          phoneNumber: serviceRequest.customer.phone,
          password: '',
        },
        deliveryInAddress: {
          id: this.CC_avs.includes(serviceRequest.serviceRequest.availableServiceId) ? null : serviceRequest.shippingAddressData.id,
          country: {
            label: this.CC_avs.includes(serviceRequest.serviceRequest.availableServiceId) ? 'Magyarország' : serviceRequest.shippingAddressData.countryLabel,
            countryCode: this.CC_avs.includes(serviceRequest.serviceRequest.availableServiceId) ? 'HUN' : serviceRequest.shippingAddressData.countryCode,
          },
          city: this.CC_avs.includes(serviceRequest.serviceRequest.availableServiceId) ? '' : serviceRequest.shippingAddressData.city,
          postalCode: this.CC_avs.includes(serviceRequest.serviceRequest.availableServiceId) ? '' : serviceRequest.shippingAddressData.zipCode,
          publicPlaceName: this.CC_avs.includes(serviceRequest.serviceRequest.availableServiceId) ? '' : serviceRequest.shippingAddressData.publicPlaceName,
          publicPlaceType: this.CC_avs.includes(serviceRequest.serviceRequest.availableServiceId) ? '' : serviceRequest.shippingAddressData.publicPlaceType,
          streetNumber: this.CC_avs.includes(serviceRequest.serviceRequest.availableServiceId) ? '' : serviceRequest.shippingAddressData.houseNumber,
          other: this.CC_avs.includes(serviceRequest.serviceRequest.availableServiceId) ? null : serviceRequest.shippingAddressData.otherAddressInfo,
          label: '',
          billingName: this.CC_avs.includes(serviceRequest.serviceRequest.availableServiceId) ? '' : serviceRequest.shippingAddressData.billingName,
          taxNumber: null,
          accountNumber: null,
          isCompany: 0,
        },
        deliveryOutAddress: {
          id: serviceRequest.shippingOutAddressData.id,
          country: {
            label: serviceRequest.shippingOutAddressData.countryLabel,
            countryCode: serviceRequest.shippingOutAddressData.countryCode,
          },
          city: serviceRequest.shippingOutAddressData.city,
          postalCode: serviceRequest.shippingOutAddressData.zipCode,
          publicPlaceName: serviceRequest.shippingOutAddressData.publicPlaceName,
          publicPlaceType: serviceRequest.shippingOutAddressData.publicPlaceType,
          streetNumber: serviceRequest.shippingOutAddressData.houseNumber,
          other: serviceRequest.shippingOutAddressData.otherAddressInfo,
          label: '',
          billingName: serviceRequest.shippingOutAddressData.billingName,
          taxNumber: null,
          accountNumber: null,
          isCompany: 0,
        },
        billingAddress: {
          id: serviceRequest.billingAddressData.id,
          country: {
            label: serviceRequest.billingAddressData.countryLabel,
            countryCode: serviceRequest.billingAddressData.countryCode
          },
          city: serviceRequest.billingAddressData.city,
          postalCode: serviceRequest.billingAddressData.zipCode,
          publicPlaceName: serviceRequest.billingAddressData.publicPlaceName,
          publicPlaceType: serviceRequest.billingAddressData.publicPlaceType,
          streetNumber: serviceRequest.billingAddressData.houseNumber,
          other: serviceRequest.billingAddressData.otherAddressInfo,
          label: '',
          billingName: serviceRequest.billingAddressData.billingName,
          taxNumber: serviceRequest.billingAddressData.taxNumber,
          accountNumber: null,
          isCompany: serviceRequest.billingAddressData.isCompany,
        },
        gdpr: false,
        termsAndCond: false,
        generalInfos: false,
        accountRegistration: null,
        isAutomaticQuotationAccepting: null,
        isVipService: null,
        isAcceptanceOfUsedParts: null,
        calculatedRepairPrice: null,
        calcDataId: null,
      };
    },
    /*async randomImei(type) {
      const randomIdentifier = await this.$store.dispatch('randomImeiGenerator');
      // console.log(`%crandomIdentifier: ${randomIdentifier} - hossz: ${randomIdentifier.toString().length}`, 'background-color: #00cec9; color: #ffffff; font-size: 12px;');
      if (type === 1) {
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn1 = randomIdentifier;
      } else if (type === 2) {
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn2 = randomIdentifier;
      } else if (type === 3) {
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn3 = randomIdentifier;
      }
    },*/
    async CheckIsUserCanSeeTheForm() {
      return true;
      /*if (this.$store.state.authModule.isAuthenticated) {
        if (this.$store.state.authModule.user) {
          const inc = this.availableCustomerTypes.findIndex(item => {
            return item.code === this.$store.state.authModule.user.profile.customerType;
          });
          // console.log('inc: ', inc);
          return inc > -1;
        } else {
          return false;
        }
      } else {
        return false;
      }*/
    },
    async getRequestById(crmRequestId) {
      const response = await this.$store.dispatch('getRequestById', crmRequestId);
      // console.log('getRequestById response: ', response);
      if (response.status === 200) {
        return response.data;
      }
    },
    async getBrand() {
      //
    },
    setSelectedService() {
      //
    },
    async setProductGroupsSelectField() {
      this.selectFieldOptions.productGroups = await this.$store.dispatch('getAllProductGroups');
    },
    async setDates() {
      const now = new Date();
      // const date = dayjs(now).subtract(3, 'day');
      this.maxDate = now.toISOString().substr(0, 10);
    },
    async setServiceRequestFormCountry() {
      if (this.$i18n.locale === 'hu-HU') {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countries[0];
        this.serviceRequestForm.deliveryOutAddress.country = this.selectFieldOptions.countries[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countries[0];
      } else if (this.$i18n.locale === 'de-DE') {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesDE[0];
        this.serviceRequestForm.deliveryOutAddress.country = this.selectFieldOptions.countriesDE[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesDE[0];
      } else {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesEN[0];
        this.serviceRequestForm.deliveryOutAddress.country = this.selectFieldOptions.countriesEN[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesEN[0];
      }
    },
    async setCustomerDatas() {
      this.serviceRequestForm.customer.firstName = this.$store.state.authModule.user.profile.firstName;
      this.serviceRequestForm.customer.lastName = this.$store.state.authModule.user.profile.lastName;
      this.serviceRequestForm.customer.email = this.$store.state.authModule.user.profile.email;
      this.serviceRequestForm.customer.phoneNumber = this.$store.state.authModule.user.profile.phone;
      this.serviceRequestForm.customer.id = getUid();
    },
    setCustomerAddresses() {
      this.$store.state.authModule.user.addresses.forEach((item, index) => {
        let temp = {};
        if (item.addressType.id === 1) {
          if (!this.CC_avs.includes(this.oldAVS)) {
            temp.id = item.id;
            temp.country = {
              countryCode: item.countryCode,
              label: item.countryLabel,
            };
            temp.city = item.city;
            temp.postalCode = item.zipCode;
            temp.publicPlaceName = item.publicPlaceName;
            temp.publicPlaceType = item.publicPlaceType;
            temp.streetNumber = item.houseNumber;
            temp.other = item.otherAddressInfo;
            temp.label = `${item.zipCode} ${item.city}, ${item.publicPlaceName} ${item.publicPlaceType} ${item.houseNumber}`;
            temp.billingName = '';
            temp.taxNumber = null;
            temp.accountNumber = null;
            temp.isDefault = item.isDefault;
            temp.isCompany = item.isCompany;
            this.selectFieldOptions.customerShippingAddresses.push(temp);
          }
        } else if (item.addressType.id === 2) {
          temp.id = item.id;
          temp.country = {
            countryCode: item.countryCode,
            label: item.countryLabel,
          };
          temp.city = item.city;
          temp.postalCode = item.zipCode;
          temp.publicPlaceName = item.publicPlaceName;
          temp.publicPlaceType = item.publicPlaceType;
          temp.streetNumber = item.houseNumber;
          temp.other = item.otherAddressInfo;
          temp.billingName = item.billingName;
          temp.taxNumber = null;
          temp.accountNumber = null;
          temp.label = `${item.billingName} - ${item.zipCode} ${item.city}, ${item.publicPlaceName} ${item.publicPlaceType} ${item.houseNumber}`;
          temp.isDefault = item.isDefault;
          temp.isCompany = item.isCompany;
          this.selectFieldOptions.customerBillingAddresses.push(temp);
        } else if (item.addressType.id === 3) {
          temp.id = item.id;
          temp.country = {
            countryCode: item.countryCode,
            label: item.countryLabel,
          };
          temp.city = item.city;
          temp.postalCode = item.zipCode;
          temp.publicPlaceName = item.publicPlaceName;
          temp.publicPlaceType = item.publicPlaceType;
          temp.streetNumber = item.houseNumber;
          temp.other = item.otherAddressInfo;
          temp.isCompany = item.isCompany;
          temp.label = `${item.zipCode} ${item.city}, ${item.publicPlaceName} ${item.publicPlaceType} ${item.houseNumber}`;
          this.selectFieldOptions.customerShippingOutAddresses.push(temp);
        }
      });
      // this.selectFieldOptions.customerShippingAddresses.push({id: '5dC]Y??9f@TmbJFv', label: 'Új címet adok meg'});
      //this.customerShippingAddress = this.selectFieldOptions.customerShippingAddresses[0];
      // this.selectFieldOptions.customerBillingAddresses.push({id: '5dC]Y??9f@TmbJFv', label: 'Új címet adok meg'});
    },
    deleteIdenticalAddresses(array) {
      const goodArray = array;
      // console.log('eredeti:');
      // console.table(goodArray);
      // console.log('START --------------');
      goodArray.sort((a, b) => ((a.isDefault === b.isDefault) ? 0 : a.isDefault ? -1 : 1));
      for (let a = 0; a < goodArray.length; a++) {
        const item = goodArray[a];
        // console.log(`${a}. item: `, item);
        if (item) {
          // console.log(`${a}. item.isDefault: `, item.isDefault);
          for (let b = a + 1; b < goodArray.length; b++) {
            const item2 = goodArray[b];
            // console.log(`${b}. item2: `, item2);
            if (item2) {
              // console.log(`${b}. item2.isDefault: `, item2.isDefault);
              if (item.billingName === item2.billingName
                && item.city === item2.city
                && item.houseNumber === item2.houseNumber
                && item.publicPlaceName === item2.publicPlaceName
                && item.publicPlaceType === item2.publicPlaceType
                && item.zipCode === item2.zipCode) {
                // console.log(`${a}. és ${b}. megegyezik - Törlés ${b}.`);
                delete goodArray[b];
              }
            }
          }
        }
      }
      // console.log('END --------------');
      const goodArrayFiltered = goodArray.filter((e) => e != null);
      // console.log('átalakított:');
      // console.table(goodArrayFiltered);
      // console.log('%cend delete', 'color: #FFFFFF; font-size: 12px; background: #6c5ce7;');
      return goodArrayFiltered;
    },
    setDefaultAddresses() {
      const shippingDefaultIndex = this.selectFieldOptions.customerShippingAddresses.findIndex((item) => {
        return item.isDefault;
      });
      if (shippingDefaultIndex < 0) {
        this.customerShippingAddress = this.selectFieldOptions.customerShippingAddresses[0];
      } else {
        this.customerShippingAddress = this.selectFieldOptions.customerShippingAddresses[shippingDefaultIndex];
      }
      const shippingOutDefaultIndex = this.selectFieldOptions.customerShippingOutAddresses.findIndex((item) => {
        return item.isDefault;
      });
      if (shippingOutDefaultIndex < 0) {
        this.customerShippingOutAddress = this.selectFieldOptions.customerShippingOutAddresses[0];
      } else {
        this.customerShippingOutAddress = this.selectFieldOptions.customerShippingOutAddresses[shippingDefaultIndex];
      }
      const billingDefaultIndex = this.selectFieldOptions.customerBillingAddresses.findIndex((item) => {
        return item.isDefault;
      });
      if (billingDefaultIndex < 0) {
        this.customerBillingAddress = this.selectFieldOptions.customerBillingAddresses[0];
      } else {
        this.customerBillingAddress = this.selectFieldOptions.customerBillingAddresses[billingDefaultIndex];
      }
    },
    setProcessType() {
      this.serviceRequestForm.processType = 1;
      if (getToken() && getUid()) {
        if (!this.serviceRequestForm.deliveryInAddress.id && !this.serviceRequestForm.billingAddress.id) {
          this.serviceRequestForm.processType = 4;
        } else if (!this.serviceRequestForm.billingAddress.id) {
          this.serviceRequestForm.processType = 3;
        } else if (!this.serviceRequestForm.deliveryInAddress.id) {
          this.serviceRequestForm.processType = 2;
        } else {
          this.serviceRequestForm.processType = 5;
        }
      }
    },
    networkCheck() {
      return Object.keys(this.serviceRequestForm.serviceRequest.networkLock).length === 0;
    },
    nextStep() {
      this.step += 1;
    },
    async sendContactForm() {
      if(this.documentCheck()) {
        this.openLoadingDialog();
        if(this.serviceRequestForm.serviceRequest.dateOfPurchase === ""){
          this.serviceRequestForm.serviceRequest.dateOfPurchase = null;
        }
        if (this.serviceRequestForm.serviceRequest.dateOfPurchase === '0000-00-00') {
          this.responseDialog.response.error = true;
          this.responseDialog.response.message = 'ERROR_MESSAGES.INVALID_DATE';
          this.controlDialogs();
        } else {
          // CÍMKEZELÉS START
          if (this.$store.state.authModule.isAuthenticated || this.isReusedForm) {
            if (this.deliveryOutIsEqualDeliveryIn) {
              this.serviceRequestForm.deliveryOutAddress = await this.findCustomerCorrespondingAddress(this.serviceRequestForm.deliveryInAddress, 3, false);
            }
            this.serviceRequestForm.billingAddress = await this.findCustomerCorrespondingAddress(this.serviceRequestForm.deliveryInAddress, 2, true);
            /*if (this.billingAddressIsEqualdeliveryInAddress) {
              this.serviceRequestForm.billingAddress.billingName = `${this.serviceRequestForm.customer.lastName} ${this.serviceRequestForm.customer.firstName}`;
            }*/
          } else {
            if (this.deliveryOutIsEqualDeliveryIn) {
              this.serviceRequestForm.deliveryOutAddress = this.serviceRequestForm.deliveryInAddress;
            }
            /*if (this.billingAddressIsEqualdeliveryInAddress) {
              this.serviceRequestForm.billingAddress.billingName = `${this.serviceRequestForm.customer.lastName} ${this.serviceRequestForm.customer.firstName}`;
            }*/
            this.serviceRequestForm.billingAddress = this.serviceRequestForm.deliveryInAddress;
            this.serviceRequestForm.billingAddress.billingName = `${this.serviceRequestForm.customer.lastName} ${this.serviceRequestForm.customer.firstName}`;
          }
          // CÍMKEZELÉS END
          // this.serviceRequestForm.deliveryOutAddress = this.$store.getters.getDeliveryOutAddresses;
          // this.serviceRequestForm.deliveryOutAddress = this.serviceRequestForm.deliveryInAddress;
          /* if (this.serviceRequestForm.serviceRequest.availableServiceId === 32) {
            this.serviceRequestForm.billingAddress = this.serviceRequestForm.deliveryInAddress;
          } */
          /*if (!this.$store.state.authModule.isAuthenticated) {
            this.serviceRequestForm.billingAddress = this.serviceRequestForm.deliveryInAddress;
            this.serviceRequestForm.billingAddress.billingName = `${this.serviceRequestForm.customer.lastName} ${this.serviceRequestForm.customer.firstName}`;
          }*/
          if (this.networkCheck()) {
            this.serviceRequestForm.serviceRequest.networkLock = this.selectFieldOptions.networkLock[0];
          }
          this.serviceRequestForm.serviceRequest.mobilProvider = this.serviceRequestForm.serviceRequest.networkLock;
          this.setProcessType();
          if (this.serviceRequestForm.accountRegistration === 'no' || this.serviceRequestForm.accountRegistration === null) {
            this.serviceRequestForm.customer.password = null;
          }
          // Purchase Price ellenőrzése
          if (this.serviceRequestForm.serviceRequest.purchasePrice === '') {
            this.serviceRequestForm.serviceRequest.purchasePrice = null;
          }
          if (this.serviceRequestForm.serviceRequest.purchasePrice) {
            if (this.serviceRequestForm.serviceRequest.purchasePrice.length < 1) {
              this.serviceRequestForm.serviceRequest.purchasePrice = null;
            }
          }
          // duplikáció ellenőrzése
          const duplicationResponse = await this.$store.dispatch('CheckDuplication',
            {
              email: this.serviceRequestForm.customer.email,
              identifiers: [
                this.serviceRequestForm.serviceRequest.uniqueIdentifierIn1,
                this.serviceRequestForm.serviceRequest.uniqueIdentifierIn2,
                this.serviceRequestForm.serviceRequest.uniqueIdentifierIn3,
              ]
            }
          );
          if (duplicationResponse !== 400) {
            if ( this.deliveryMethod === 1 || !this.deliveryMethod ) {
              delete this.serviceRequestForm['deliveryData']
            }
            if (this.deliveryMethod === 2) {
              this.setFoxpostData()
              this.serviceRequestForm.deliveryOutAddress = this.serviceRequestForm.deliveryInAddress;
              if (this.serviceRequestForm.deliveryData.parcelUserPhone.length <= 9) {
                this.serviceRequestForm.deliveryData.parcelUserPhone = '36' + this.serviceRequestForm.deliveryData.parcelUserPhone;
              }
            }
            if (this.serviceRequestForm.customer.phoneNumber.length <= 9) {
              this.serviceRequestForm.customer.phoneNumber = '36' + this.serviceRequestForm.customer.phoneNumber;
            }
            const response = await this.$store.dispatch('sendMobilfutarRequest',
              {form: this.serviceRequestForm, attachment: this.attachment});
            // console.log('sendContactForm response: ', response);
            if (response.status === 200) {
              this.responseDialog.response.error = false;
              this.responseDialog.response.message = 'SUCCESS_MESSAGES.REQUEST_SEND';
            } else if (response.status === 201) {
              this.responseDialog.response.error = false;
              this.responseDialog.response.message = 'SUCCESS_MESSAGES.REQUEST_SEND';
            } else if (response.status === 422) {
              this.responseDialog.response.error = true;
              this.responseDialog.response.message = 'ERROR_MESSAGES.USER_EXISTS';
            } else if (response.status === 403) {
              this.responseDialog.response.error = true;
              this.responseDialog.response.message = 'ERROR_MESSAGES.USER_EXISTS';
            } else if (response.status === 405) {
              this.responseDialog.response.error = true;
              this.responseDialog.response.message = 'ERROR_MESSAGES.REQUEST_SEND_1';
            } else {
              this.responseDialog.response.error = true;
              this.responseDialog.response.message = 'ERROR_MESSAGES.REQUEST_SEND_2';
            }
          } else {
            this.responseDialog.response.error = true;
            this.responseDialog.response.message = 'ERROR_MESSAGES.DUPLICATION';
          }
          this.controlDialogs();
        }
      } else {
        this.openNoFileDialog();
      }
    },
    openLoadingDialog() {
      this.loadingDialog.visible = true;
    },
    closeLoadingDialog() {
      this.loadingDialog.visible = false;
    },
    setupResponseDialog(message) {
      this.responseDialog.response.message = message;
    },
    openResponseDialog() {
      this.responseDialog.visible = true;
    },
    closeResponseDialog() {
      this.responseDialog.visible = false;
      if (!this.responseDialog.response.error) {
        this.$router.push({ name: 'iranyitopult'});
      }
    },
    openNoFileDialog() {
      this.noFileDialog.visible = true;
    },
    closeNoFileDialog() {
      this.noFileDialog.visible = false;
    },
    closeNotValidPdfDialog() {
      this.attachmentChecker.visible = false;
    },
    controlDialogs() {
      setTimeout(this.closeLoadingDialog, 3000);
      setTimeout(this.openResponseDialog, 3200);
    },
    async imeiCheck() {
      /*const response = await this.$store.dispatch(LUHN_CHECK, this.serviceRequestForm.serviceRequest.deviceIdentifier);
      if (response) {
        // console.log('LUHN_CHECK: ', response);
        if (response.error) {
          this.imeiHint = 'imeiError';
        } else {
          this.imeiHint = response.message;
        }
      }*/
    },
    /**
     *
     * Ellenörzi, hogy számla vagy garjegy van-e feltöltve
     *
     **/
    documentCheck() {
      return true;
      // garanciális esetén számla kötelező
      /* if (this.serviceRequestForm.serviceRequest.warrantyType.id === 1) {
        return !(Array.isArray(this.attachment.invoice) || this.attachment.invoice === null);
      } else {
        return true;
      } */
      // garanciális esetén vagy a számla vagy a jótállási kötelező
      /*if (this.serviceRequestForm.serviceRequest.warrantyType.id === 1) {
        const warrantyCardSize = this.attachment.warrantyCard && this.attachment.warrantyCard.size ? this.attachment.warrantyCard.size : 0;
        const invoiceSize = this.attachment.invoice && this.attachment.invoice.size ? this.attachment.invoice.size : 0;
        // console.log(`warrantyCardSize - ${warrantyCardSize}, invoiceSize - ${invoiceSize}`);
        if (invoiceSize < 1) {
          return warrantyCardSize >= 1;
        } else {
          return true;
        }
        /!* if ((Array.isArray(this.attachment.invoice) || this.attachment.invoice === null) || this.attachment.invoice) {
          return !((Array.isArray(this.attachment.warrantyCard) || this.attachment.warrantyCard === null) || this.attachment.warrantyCard);
        } else {
          return true;
        } *!/
      } else {
        return true;
      }*/
    },
    clearFile(name) {
      if (name === 'invoice') {
        this.attachment.invoice = null;
      } else if (name === 'warrantyCard') {
        this.attachment.warrantyCard = null;
      }
    },
    /**
     * SZÁLLÍTÁSI CÍM MEGADÁSÁHOZ FGV-EK
     */
    async getPostalCodesByCountry(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodes = response.data;
        this.postalCodeSearchIsLoading = false;
        this.selectFieldOptions.postalCodes.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCode() {
      let postalcode = 0;
      if (this.serviceRequestForm.deliveryInAddress.postalCode && this.serviceRequestForm.deliveryInAddress.postalCode.address) {
        postalcode = this.serviceRequestForm.deliveryInAddress.postalCode.address.postalCode;
        this.serviceRequestForm.deliveryInAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        if (!response.data[0].address.city) {
          this.selectFieldOptions.cities = [];
        } else {
          this.selectFieldOptions.cities = response.data;
          this.citySearchIsLoading = false;
          this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
        }
      }
    },
    async getPostalCodeByCity(value) {
      if (this.serviceRequestForm.deliveryInAddress.postalCode === null) {
        this.serviceRequestForm.deliveryInAddress.postalCode = ''
      }
      const response = await HEREGETPOSTALCODESBYCITY(value, this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.cities = response.data;
        this.citySearchIsLoading = false;
        this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCode(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.serviceRequestForm.deliveryInAddress.city, this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streets = response.data;
        this.streetSearchIsLoading = false;
        this.selectFieldOptions.streets.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelect() {
      await this.getCityByPostalCode();
      if (this.selectFieldOptions.cities.length === 2) {
        this.serviceRequestForm.deliveryInAddress.city = this.selectFieldOptions.cities[1].address.city;
        this.citySearch = this.selectFieldOptions.cities[1].address.city;
      }
      this.serviceRequestForm.deliveryInAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryInAddress.streetNumber = '';
      this.streetIdeiglenes = '';
      this.streetSearch = null;
    },
    onPublicPlaceSelect() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenes && this.streetIdeiglenes.length > 0) {
        // console.log('1');
        street = this.streetIdeiglenes.substring(0, this.streetIdeiglenes.lastIndexOf(' '));
        type = this.streetIdeiglenes.substring(this.streetIdeiglenes.lastIndexOf(' '), this.streetIdeiglenes.length + 1).trim();
        if (this.streetIdeiglenes.lastIndexOf(' ') > 0) {
          // console.log('1a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          // console.log('1b');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = this.streetIdeiglenes;
          type = this.selectFieldOptions.streets[1].address.street.substring(this.selectFieldOptions.streets[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streets[1].address.street.length + 1).trim();
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenes && this.streetIdeiglenes.address) {
        // console.log('2');
        street = this.streetIdeiglenes.address.street.substring(0, this.streetIdeiglenes.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenes.address.street.substring(this.streetIdeiglenes.address.street.lastIndexOf(' '), this.streetIdeiglenes.address.street.length + 1).trim();
        if (this.streetIdeiglenes.address.street.lastIndexOf(' ') > 0) {
          // console.log('2a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          console.log('2b');
        }
      }
      //
      //
    },
    onPublicPlaceSelectFoxpost() {
      let street = '';
      let type = '';
      let houseNumber = '';
      let streetideglenes2 = '';
      if (this.streetIdeiglenes && this.streetIdeiglenes.length > 0) {
        // console.log('1');
        streetideglenes2 = this.streetIdeiglenes.substring(0, this.streetIdeiglenes.lastIndexOf(' '));
        houseNumber = this.streetIdeiglenes.substring(this.streetIdeiglenes.lastIndexOf(' '), this.streetIdeiglenes.length + 1).trim();
        street = streetideglenes2.substring(0,streetideglenes2.lastIndexOf(' '));
        type = streetideglenes2.substring(streetideglenes2.lastIndexOf(' '), streetideglenes2.length + 1).trim();
        console.log(streetideglenes2)
        console.log(houseNumber)
        console.log(street)
        console.log(type)
        if (this.streetIdeiglenes.lastIndexOf(' ') > 0) {
          // console.log('1a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
          this.serviceRequestForm.deliveryInAddress.streetNumber = houseNumber
        } else {
          // console.log('1b');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = this.streetIdeiglenes;
          type = this.selectFieldOptions.streets[1].address.street.substring(this.selectFieldOptions.streets[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streets[1].address.street.length + 1).trim();
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenes && this.streetIdeiglenes.address) {
        // console.log('2');
        street = this.streetIdeiglenes.address.street.substring(0, this.streetIdeiglenes.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenes.address.street.substring(this.streetIdeiglenes.address.street.lastIndexOf(' '), this.streetIdeiglenes.address.street.length + 1).trim();
        if (this.streetIdeiglenes.address.street.lastIndexOf(' ') > 0) {
          // console.log('2a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          console.log('2b');
        }
      }
      //
      //
    },
    preventEnter() {
      //
    },
    clearAllAddressInputs() {
      this.selectFieldOptions.postalCodes = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.cities = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streets = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearch = '';
      this.postalCodeSearchIsLoading = false;
      this.citySearch = '';
      this.citySearchIsLoading = false;
      this.streetSearch = '';
      this.streetSearchIsLoading = false;
      this.streetIdeiglenes = '';
      this.serviceRequestForm.deliveryInAddress.postalCode = '';
      this.serviceRequestForm.deliveryInAddress.city = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryInAddress.streetNumber = '';
    },
    saveDeliveryInAddressData() {
      this.deliveryInAddressString = this.serviceRequestForm.deliveryInAddress.postalCode + ' '
        + this.serviceRequestForm.deliveryInAddress.city + ', '
        + this.serviceRequestForm.deliveryInAddress.publicPlaceName + ' '
        + this.serviceRequestForm.deliveryInAddress.publicPlaceType + ' '
        + this.serviceRequestForm.deliveryInAddress.streetNumber;
      this.deliveryInAddressModal = false;
    },
    cancelDeliveryInAddressModal() {
      this.deliveryInAddressModal = false;
    },
    stepBackDeliveryInAddressModal() {
      this.shippingAddressStepper = 1;
      this.deliveryInAddressForm = false;
    },
    /**
     * KISZÁLLÍTÁSI CÍM MEGADÁSÁHOZ FGV-EK
     */
    async getPostalCodesByCountryDeliveryOut(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.serviceRequestForm.deliveryOutAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodesOut = response.data;
        this.deliveryOutAddressPostalCodeSearchIsLoading = false;
        this.selectFieldOptions.postalCodesOut.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCodeDeliveryOut() {
      let postalcode = 0;
      if (this.serviceRequestForm.deliveryOutAddress.postalCode && this.serviceRequestForm.deliveryOutAddress.postalCode.address) {
        postalcode = this.serviceRequestForm.deliveryOutAddress.postalCode.address.postalCode;
        this.serviceRequestForm.deliveryOutAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.serviceRequestForm.deliveryOutAddress.postalCode, this.serviceRequestForm.deliveryOutAddress.country.countryCode);
      if (!response.error) {
        if (!response.data[0].address.city) {
          this.selectFieldOptions.citiesOut = [];
        } else {
          this.selectFieldOptions.citiesOut = response.data;
          this.deliveryOutAddressCitySearchIsLoading = false;
          this.selectFieldOptions.citiesOut.unshift({ header: this.$t('inputMessages.startTypingCity') });
        }
      }
    },
    async getPostalCodeByCityDeliveryOut(value) {
      if (this.serviceRequestForm.deliveryOutAddress.postalCode === null) {
        this.serviceRequestForm.deliveryOutAddress.postalCode = ''
      }
      const response = await HEREGETPOSTALCODESBYCITY(value, this.serviceRequestForm.deliveryOutAddress.postalCode, this.serviceRequestForm.deliveryOutAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.citiesOut = response.data;
        this.deliveryOutAddressCitySearchIsLoading = false;
        this.selectFieldOptions.citiesOut.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCodeDeliveryOut(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value,this.serviceRequestForm.deliveryOutAddress.city, this.serviceRequestForm.deliveryOutAddress.postalCode, this.serviceRequestForm.deliveryOutAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streetsOut = response.data;
        this.deliveryOutAddressStreetSearchIsLoading = false;
        this.selectFieldOptions.streetsOut.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelectDeliveryOut() {
      await this.getCityByPostalCodeDeliveryOut();
      if (this.selectFieldOptions.citiesOut.length === 2) {
        this.serviceRequestForm.deliveryOutAddress.city = this.selectFieldOptions.citiesOut[1].address.city;
        this.citySearch = this.selectFieldOptions.citiesOut[1].address.city;
      }
      this.serviceRequestForm.deliveryOutAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryOutAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryOutAddress.streetNumber = '';
      this.deliveryOutAddressStreetIdeiglenes = '';
      this.deliveryOutAddressStreetSearch = null;
    },
    onPublicPlaceSelectDeliveryOut() {
      let street = '';
      let type = '';
      if (this.deliveryOutAddressStreetIdeiglenes && this.deliveryOutAddressStreetIdeiglenes.length > 0) {
        // console.log('1');
        street = this.deliveryOutAddressStreetIdeiglenes.substring(0, this.deliveryOutAddressStreetIdeiglenes.lastIndexOf(' '));
        type = this.deliveryOutAddressStreetIdeiglenes.substring(this.deliveryOutAddressStreetIdeiglenes.lastIndexOf(' '), this.deliveryOutAddressStreetIdeiglenes.length + 1).trim();
        if (this.deliveryOutAddressStreetIdeiglenes.lastIndexOf(' ') > 0) {
          // console.log('1a');
          this.serviceRequestForm.deliveryOutAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryOutAddress.publicPlaceType = type;
        } else {
          // console.log('1b');
          this.serviceRequestForm.deliveryOutAddress.publicPlaceName = this.deliveryOutAddressStreetIdeiglenes;
          type = this.selectFieldOptions.streetsOut[1].address.street.substring(this.selectFieldOptions.streetsOut[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streetsOut[1].address.street.length + 1).trim();
          this.serviceRequestForm.deliveryOutAddress.publicPlaceType = type;
        }
      } else if (this.deliveryOutAddressStreetIdeiglenes && this.deliveryOutAddressStreetIdeiglenes.address) {
        // console.log('2');
        street = this.deliveryOutAddressStreetIdeiglenes.address.street.substring(0, this.deliveryOutAddressStreetIdeiglenes.address.street.lastIndexOf(' '));
        type = this.deliveryOutAddressStreetIdeiglenes.address.street.substring(this.deliveryOutAddressStreetIdeiglenes.address.street.lastIndexOf(' '), this.deliveryOutAddressStreetIdeiglenes.address.street.length + 1).trim();
        if (this.deliveryOutAddressStreetIdeiglenes.address.street.lastIndexOf(' ') > 0) {
          // console.log('2a');
          this.serviceRequestForm.deliveryOutAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryOutAddress.publicPlaceType = type;
        } else {
          // console.log('2b');
        }
      }
      //
      //
    },
    preventEnterDeliveryOut() {
      //
    },
    clearAllAddressInputsDeliveryOut() {
      this.selectFieldOptions.postalCodesOut = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.citiesOut = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streetsOut = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.deliveryOutAddressPostalCodeSearch = '';
      this.deliveryOutAddressPostalCodeSearchIsLoading = false;
      this.deliveryOutAddressCitySearch = '';
      this.deliveryOutAddressCitySearchIsLoading = false;
      this.deliveryOutAddressStreetSearch = '';
      this.deliveryOutAddressStreetSearchIsLoading = false;
      this.deliveryOutAddressStreetIdeiglenes = '';
      this.serviceRequestForm.deliveryOutAddress.postalCode = '';
      this.serviceRequestForm.deliveryOutAddress.city = '';
      this.serviceRequestForm.deliveryOutAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryOutAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryOutAddress.streetNumber = '';
    },
    saveDeliveryInAddressDataDeliveryOut() {
      this.deliveryOutAddressString = this.serviceRequestForm.deliveryOutAddress.postalCode + ' '
        + this.serviceRequestForm.deliveryOutAddress.city + ', '
        + this.serviceRequestForm.deliveryOutAddress.publicPlaceName + ' '
        + this.serviceRequestForm.deliveryOutAddress.publicPlaceType + ' '
        + this.serviceRequestForm.deliveryOutAddress.streetNumber;
      this.deliveryOutAddressModal = false;
    },
    cancelDeliveryOutAddressModal() {
      this.deliveryOutAddressModal = false;
    },
    stepBackDeliveryOutAddressModal() {
      this.shippingAddressOutStepper = 1;
      this.deliveryOAddressForm = false;
    },
    /***
     * SZÁMLÁZÁSI CÍM MEGADÁSÁHOZ A FGV-EK
     */
    async getPostalCodesByCountryInvoice(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodesInvoice = response.data;
        this.postalCodeSearchIsLoadingInvoice = false;
        this.selectFieldOptions.postalCodesInvoice.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCodeInvoice() {
      let postalcode = 0;
      if (this.serviceRequestForm.billingAddress.postalCode && this.serviceRequestForm.billingAddress.postalCode.address) {
        postalcode = this.serviceRequestForm.billingAddress.postalCode.address.postalCode;
        this.serviceRequestForm.billingAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        if (!response.data[0].address.city) {
          this.selectFieldOptions.citiesInvoice = [];
        } else {
          this.selectFieldOptions.citiesInvoice = response.data;
          this.citySearchIsLoadingInvoice = false;
          this.selectFieldOptions.citiesInvoice.unshift({ header: this.$t('inputMessages.startTypingCity') });
        }
      }
    },
    async getPostalCodeByCityInvoice(value) {
      if (this.serviceRequestForm.billingAddress.postalCode === null) {
        this.serviceRequestForm.billingAddress.postalCode = ''
      }
      const response = await HEREGETPOSTALCODESBYCITY(value, this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.citiesInvoice = response.data;
        this.citySearchIsLoadingInvoice = false;
        this.selectFieldOptions.citiesInvoice.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCodeInvoice(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.serviceRequestForm.billingAddress.city, this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streetsInvoice = response.data;
        this.streetSearchIsLoadingInvoice = false;
        this.selectFieldOptions.streetsInvoice.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelectInvoice() {
      await this.getCityByPostalCodeInvoice();
      if (this.selectFieldOptions.citiesInvoice.length === 2) {
        this.serviceRequestForm.billingAddress.city = this.selectFieldOptions.citiesInvoice[1].address.city;
        this.citySearchInvoice = this.selectFieldOptions.citiesInvoice[1].address.city;
      }
      this.serviceRequestForm.billingAddress.publicPlaceName = '';
      this.serviceRequestForm.billingAddress.publicPlaceType = '';
      this.serviceRequestForm.billingAddress.streetNumber = '';
      this.streetIdeiglenesInvoice = '';
      this.streetSearchInvoice = null;
    },
    onPublicPlaceSelectInvoice() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.length > 0) {
        street = this.streetIdeiglenesInvoice.substring(0, this.streetIdeiglenesInvoice.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.substring(this.streetIdeiglenesInvoice.lastIndexOf(' '), this.streetIdeiglenesInvoice.length + 1).trim();
        if (this.streetIdeiglenesInvoice.lastIndexOf(' ') > 0) {
          // console.log('3a');
          this.serviceRequestForm.billingAddress.publicPlaceName = street;
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        } else {
          // console.log('3b');
          this.serviceRequestForm.billingAddress.publicPlaceName = this.streetIdeiglenesInvoice;
          type = this.selectFieldOptions.streetsInvoice[1].address.street.substring(this.selectFieldOptions.streetsInvoice[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streetsInvoice[1].address.street.length + 1).trim();
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.address) {
        street = this.streetIdeiglenesInvoice.address.street.substring(0, this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.address.street.substring(this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '), this.streetIdeiglenesInvoice.address.street.length + 1).trim();
        if (this.streetIdeiglenesInvoice.address.street.lastIndexOf(' ') > 0) {
          // console.log('4a');
          this.serviceRequestForm.billingAddress.publicPlaceName = street;
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        } else {
          console.log('4b');
        }
      }
    },
    clearAllAddressInputsInvoice() {
      this.selectFieldOptions.postalCodesInvoice = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.citiesInvoice = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streetsInvoice = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearchInvoice = '';
      this.postalCodeSearchIsLoadingInvoice = false;
      this.citySearchInvoice = '';
      this.citySearchIsLoadingInvoice = false;
      this.streetSearchInvoice = '';
      this.streetSearchIsLoadingInvoice = false;
      this.streetIdeiglenesInvoice = '';
      this.serviceRequestForm.billingAddress.postalCode = '';
      this.serviceRequestForm.billingAddress.city = '';
      this.serviceRequestForm.billingAddress.publicPlaceType = '';
      this.serviceRequestForm.billingAddress.publicPlaceName = '';
      this.serviceRequestForm.billingAddress.streetNumber = '';
    },
    saveBillingAddressData() {
      this.billingAddressString = this.serviceRequestForm.billingAddress.billingName
        + ' - ' + this.serviceRequestForm.billingAddress.postalCode + ' '
        + this.serviceRequestForm.billingAddress.city + ', '
        + this.serviceRequestForm.billingAddress.publicPlaceName + ' '
        + this.serviceRequestForm.billingAddress.publicPlaceType + ' '
        + this.serviceRequestForm.billingAddress.streetNumber;
      this.billingAddressModal = false;
    },
    stepBackBillingAddressModal() {
      this.billingAddressStepper = 1;
      this.billingAddressForm = false;
    },
    openBillingAddressModal() {
      // a meglévő szállítási címet kimentjük egy ideiglenes változóba,
      // hogy ha a user a Mégsem gombra kattint, akkor megmaradjon az előzőleg beírt cím
      this.billingAddressBackup = this.serviceRequestForm.billingAddress;
      this.billingAddressModal = true;
    },
    closeBillingAddressModalWithoutSave() {
      // Mégsem gombra kattintva visszaszerezzük az előzőleg beírt címet
      this.serviceRequestForm.billingAddress = this.billingAddressBackup;
      this.billingAddressModal = false;
      this.billingAddressBackup = null;
    },
    async callShowForm() {
      const response = await this.$store.dispatch('showForm', 'SzervizgarFutarForm');
      if (response.status === 200) {
        this.availableCustomerTypes = response.data.customerType;
        this.selectFieldOptions.warrantyType = response.data.warrantyType;
        this.selectFieldOptions.networkLock = response.data.networkLock;
        this.selectFieldOptions.brands = response.data.brand.sort((a, b) => ((a.name > b.name) ? 1 : -1));
        this.serviceRequestForm.serviceRequest.warrantyType = this.selectFieldOptions.warrantyType[0];
        // this.serviceRequestForm.serviceRequest.networkLock = this.selectFieldOptions.networkLock[0];
        this.serviceRequestForm.customer.customerType = response.data.customerType[0].code;
      }
    },
    async callShowPaymentMethodOfCustomerType(customerType) {
      const response = await this.$store.dispatch('showPaymentMethodOfCustomerType', customerType);
      if (response.status === 200) {
        if (response.data.length === 1) {
          this.serviceRequestForm.serviceRequest.paymentMethod = response.data[0];
        } else {
          response.data.sort((a, b) => ((a.name > b.name) ? 1 : -1));
        }
        this.selectFieldOptions.paymentMethods = response.data;
      }
    },
    async callShowProductGroupOfBrand(brand) {
      // const resp = await this.$store.dispatch('showProductGroupOfBrand', { brandId: brand.id, formTypeId: this.$FORM_DOLGOZOI.id });
      // console.log('resp: ', resp);
      const resp = await this.$store.dispatch('showProductGroupOfBrand', brand.id);
      this.selectFieldOptions.productGroups = resp.data.sort((a, b) => ((a.name > b.name) ? 1 : -1));
    },
    async callShowProductTypeOfBrand() {
      const response = await this.$store.dispatch('showProductTypeOfBrand',
        { brandId: this.serviceRequestForm.serviceRequest.brand.id, productGroupId: this.serviceRequestForm.serviceRequest.productGroup.id });
      if (response.status === 200) {
        response.data.sort((a, b) => ((a.productTypeShoppingName > b.productTypeShoppingName) ? 1 : -1));
        // ha APPLE brand.id = 2, akkor nem kell
        const benne = this.$NoDisplayNameBrands.includes(this.serviceRequestForm.serviceRequest.brand.id);
        if (benne) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].displayName = `${response.data[i].productTypeShoppingName}`;
          }
        } else {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].displayName = `${response.data[i].productTypeShoppingName} - ${response.data[i].gsmTipusNev}`;
          }
        }
        this.selectFieldOptions.productTypes = response.data;
        this.convertAndFindProductTypeLoggedInUserCase();
      }
    },
    convertAndFindProductTypeLoggedInUserCase() {
      let productTypeFull = this.selectFieldOptions.productTypes.find((item) => item.productTypeId === this.serviceRequestForm.serviceRequest.productType.id);
      if (!productTypeFull) {
        productTypeFull = this.selectFieldOptions.productTypes.find((item) => item.gsmTipusId === this.serviceRequestForm.serviceRequest.productType.id);
      }
      this.serviceRequestForm.serviceRequest.productTypeFull = productTypeFull;
    },
    purchaseDateIsOK(date, isOptionalField = false) {
      const inputDate = dayjs(date);
      const maxD = dayjs(this.maxDate);
      const max = dayjs(maxD).add(1, 'day');
      if (date) {
        if (date.length === 10) {
          if (new Date(date).getDate()) {
            return inputDate.isBefore(dayjs(max));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (isOptionalField) {
        return true;
      } else {
        return false;
      }
    },
    pdfChecker(file, type) {
      const pdfRegexp = new RegExp('%PDF-1.[0-7]');
      // const pdfRegexp = new RegExp('^%PDF');
      const size = file.size;
      let header = '';
      let scope = this;
      let fr = new FileReader();
      fr.readAsText(file);
      fr.onload = function (evt) {
        header = evt.target.result.substr(0, 8);
        // console.log('header: ', header);
        if (pdfRegexp.test(header) && size > 0) {
          // console.log('%cOK', 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
          scope.attachmentChecker.response.error = false;
        } else {
          // console.log('%cNEM OK', 'background-color: #c0392b; color: #ffffff; font-size: 12px;');
          scope.attachmentChecker.visible = true;
          scope.attachmentChecker.response.error = true;
          if (type === 1) {
            scope.attachment.invoice = null;
          } else {
            scope.attachment.warrantyCard = null;
          }
        }
      };
    },
    numberRules(prevGsmWorkSheetId, isOptionalField = false){
      if (prevGsmWorkSheetId && prevGsmWorkSheetId.length >= 0) {
        return false;
      } else if (isOptionalField) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    yearPickerModal(value) {
      value && setTimeout(() => (this.$refs.yearPicker.activePicker = 'YEAR'));
    },
    postalCodeSearch(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountry(value);
    },
    citySearch(value) {
      this.citySearchIsLoading = true;
      this.getPostalCodeByCity(value);
    },
    citySearchInvoice(value) {
      this.citySearchIsLoadingInvoice = true;
      this.getPostalCodeByCityInvoice(value);
    },
    deliveryOutAddressCitySearch(value) {
      this.deliveryOutAddressCitySearchIsLoading = true;
      this.getPostalCodeByCityDeliveryOut(value);
    },
    streetSearch(value) {
      if (value.length >= 1){
        this.streetSearchIsLoading = true;
        this.getStreetByPostalCode(value);
      }
    },
    deliveryOutAddressPostalCodeSearch(value) {
      this.deliveryOutAddressPostalCodeSearchIsLoading = true;
      this.getPostalCodesByCountryDeliveryOut(value);
    },
    deliveryOutAddressStreetSearch(value) {
      if (value.length >= 1) {
        this.deliveryOutAddressStreetSearchIsLoading = true;
        this.getStreetByPostalCodeDeliveryOut(value);
      }
    },
    postalCodeSearchInvoice(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountryInvoice(value);
    },
    streetSearchInvoice(value) {
      if (value.length >= 1) {
        this.streetSearchIsLoading = true;
        this.getStreetByPostalCodeInvoice(value);
      }
    },
    billingAddressIsEqualdeliveryInAddress(value) {
      if (value) {
        this.serviceRequestForm.billingAddress.billingName = this.serviceRequestForm.customer.lastName + ' ' + this.serviceRequestForm.customer.firstName;
        this.serviceRequestForm.billingAddress.postalCode = this.serviceRequestForm.deliveryInAddress.postalCode;
        this.serviceRequestForm.billingAddress.city = this.serviceRequestForm.deliveryInAddress.city;
        this.serviceRequestForm.billingAddress.publicPlaceName = this.serviceRequestForm.deliveryInAddress.publicPlaceName;
        this.serviceRequestForm.billingAddress.publicPlaceType = this.serviceRequestForm.deliveryInAddress.publicPlaceType;
        this.serviceRequestForm.billingAddress.streetNumber = this.serviceRequestForm.deliveryInAddress.streetNumber;
        this.serviceRequestForm.billingAddress.other = this.serviceRequestForm.deliveryInAddress.other;
        this.saveBillingAddressData();
      } else {
        this.clearAllAddressInputsInvoice();
        this.serviceRequestForm.billingAddress.billingName = '';
        this.serviceRequestForm.billingAddress.taxNumber = '';
        this.serviceRequestForm.billingAddress.other = '';
        this.serviceRequestForm.billingAddress.id = null;
        this.billingAddressString = '';
      }
    },
    deliveryOutIsEqualDeliveryIn(value) {
      if (value) {
        this.serviceRequestForm.deliveryOutAddress.billingName = this.serviceRequestForm.customer.lastName + ' ' + this.serviceRequestForm.customer.firstName;
        this.serviceRequestForm.deliveryOutAddress.postalCode = this.serviceRequestForm.deliveryInAddress.postalCode;
        this.serviceRequestForm.deliveryOutAddress.city = this.serviceRequestForm.deliveryInAddress.city;
        this.serviceRequestForm.deliveryOutAddress.publicPlaceName = this.serviceRequestForm.deliveryInAddress.publicPlaceName;
        this.serviceRequestForm.deliveryOutAddress.publicPlaceType = this.serviceRequestForm.deliveryInAddress.publicPlaceType;
        this.serviceRequestForm.deliveryOutAddress.streetNumber = this.serviceRequestForm.deliveryInAddress.streetNumber;
        this.serviceRequestForm.deliveryOutAddress.other = this.serviceRequestForm.deliveryInAddress.other;
        this.saveDeliveryInAddressDataDeliveryOut();
      } else {
        this.clearAllAddressInputsDeliveryOut();
        this.serviceRequestForm.deliveryOutAddress.billingName = '';
        this.serviceRequestForm.deliveryOutAddress.taxNumber = '';
        this.serviceRequestForm.deliveryOutAddress.other = '';
        this.serviceRequestForm.deliveryOutAddress.id = null;
        this.deliveryOutAddressString = '';
      }
    },
    'serviceRequestForm.serviceRequest.brand': function (newVal) {
      if (!this.isReusedForm) {
        this.serviceRequestForm.serviceRequest.productGroup = {};
        this.serviceRequestForm.serviceRequest.productTypeFull = null;
        this.serviceRequestForm.serviceRequest.productType = null;
        this.selectFieldOptions.productGroups = [];
      }
      this.callShowProductGroupOfBrand(newVal);
    },
    'serviceRequestForm.serviceRequest.productGroup': function (newVal) {
      if (!this.isReusedForm) {
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn1 = '';
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn2 = '';
        this.serviceRequestForm.serviceRequest.uniqueIdentifierIn3 = '';
        this.serviceRequestForm.serviceRequest.productTypeFull = null;
        this.selectFieldOptions.productTypes = [];
      }
      if (newVal.id) {
        this.callShowProductTypeOfBrand();
      }
    },
    'serviceRequestForm.serviceRequest.productTypeFull': function (newVal) {
      if (!this.isReusedForm) {
        this.serviceRequestForm.serviceRequest.productType = {};
        this.typeSearch = '';
      }
      if (newVal) {
        // Apple esetén gsmTipusId-t mentek itt
        if ([2].includes(this.serviceRequestForm.serviceRequest.brand.id)) {
          this.serviceRequestForm.serviceRequest.productType.id = newVal.gsmTipusId;
        } else {
          this.serviceRequestForm.serviceRequest.productType.id = newVal.productTypeId;
        }
        this.serviceRequestForm.serviceRequest.productType.name = newVal.productTypeShoppingName;
      }
    },
    /*'serviceRequestForm.serviceRequest.warrantyType': function (newVal) {
      if (newVal.id === 1) {
        this.serviceRequestForm.serviceRequest.availableServiceId = 32;
        this.serviceRequestForm.serviceRequest.paymentMethod = { id: null, name: null, lang: null};
      } else {
        this.serviceRequestForm.serviceRequest.availableServiceId = 33;
        this.serviceRequestForm.serviceRequest.paymentMethod = null;
      }
    },*/
    /*'serviceRequestForm.serviceRequest.dateOfPurchase': function (value) {
      const date = dayjs(value);
      console.log('date: ', date);
      const ok = date.isBefore(dayjs(this.maxDate));
      console.log('ok: ', ok);
    },*/
    customerBillingAddress(value) {
      // console.log('customerBillingAddress: ', value);
      if (value.id === '5dC]Y??9f@TmbJFv') {
        this.billingAddressStepper = 2;
        this.serviceRequestForm.billingAddress = {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        };
        this.setServiceRequestFormCountry();
      } else {
        this.serviceRequestForm.billingAddress = value;
        this.billingAddressString = value.label;
        this.billingAddressModal = false;
        this.billingAddressForm = true;
      }
    },
    /* 'attachment.invoice': async function(value) {
      console.log('attachment.invoice: ', value);
      if (value) {
        console.log('van file');
        this.pdfChecker(value, 1);
      }
    },
    'attachment.warrantyCard': async function(value) {
      console.log('attachment.warrantyCard: ', value);
      if (value) {
        console.log('van file');
        this.pdfChecker(value, 2);
      }
    }, */
  },
  computed: {
    alertColor() {
      return this.serviceRequestForm.prevCrmRequestId || this.serviceRequestForm.prevGsmWorkSheetId ? 'success' : 'warning';
    },
    alertIcon() {
      return this.serviceRequestForm.prevCrmRequestId || this.serviceRequestForm.prevGsmWorkSheetId ? 'mdi-check' : 'mdi-alert';
    },
    docAlertColor() {
      return this.attachment.invoice || this.attachment.warrantyCard ? 'success' : 'warning';
    },
    docAlertIcon() {
      return this.attachment.invoice || this.attachment.warrantyCard ? 'mdi-check' : 'mdi-alert';
    },
    docInvoiceIsRequired() {
      /*if (this.serviceRequestForm.serviceRequest.warrantyType.id === 1) {
        return !this.attachment.warrantyCard;
      }*/
      return false;
    },
    docWarrantyCardIsRequired() {
      /*if (this.serviceRequestForm.serviceRequest.warrantyType.id === 1) {
        return !this.attachment.invoice;
      }*/
      return false;
    },
    /*purchaseDateRule() {
      const inputDate = dayjs(this.serviceRequestForm.serviceRequest.dateOfPurchase);
      return inputDate.isBefore(dayjs(this.maxDate));
    }*/
  }
};
</script>

<style scoped>

</style>
